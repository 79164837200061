
import './FeedItem.css';
export default function FeedItemFiller() {
  const fillerText = ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";

  return (
    <div className="feedItem feedItemFiller">
      <div className="feedItemInner">
        <div className="theQuote">
          <span className="quoteParagraph">
            <span className="quoteSpansOuter">
            {fillerText}
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}
