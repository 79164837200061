import React from 'react';
import './feedItems.css'

import FeedItem from './feedItem/FeedItem.js';
import FeedItemFiller from './feedItem/feedItemFiller.js';
import InfiniteScroll from 'react-infinite-scroll-component';

// import FillerSpan from '../misc/fillerSpan.js';


import SearchBar from './searchbar/searchbar';


class FeedItems extends React.Component {
  constructor(props) {
    super(props);
    console.log("in feeditems constructor");

    let hasMore = true;
    const { userUuid, feedSelected, tagsSelected } = props;

    this.state = {
      loading: false,
      feedSelected,
      userUuid,
      tagsSelected,
      hasMore
    };

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);

    this.pullDownRefresh = this.pullDownRefresh.bind(this);
    this.fetchAdditionalHistoricData = this.fetchAdditionalHistoricData.bind(this);
    this.handleNewUsrQuote = this.handleNewUsrQuote.bind(this);
    this.handleSearchKeyPressFeedItems = this.handleSearchKeyPressFeedItems.bind(this);
    
    // this.refInfiniteScrollContainer = React.createRef();
    this.refLoader = React.createRef();

    this.fillerText = ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";


  }

  printScrollingDims(header){
 
  }

  componentDidMount() {
    console.log("feeds js componentDidMountthis.state ", this.state, " ", this.props);
  
    this.loaderEl = this.refLoader.current;
    this.printScrollingDims("Component mounted");
  }


  componentWillUpdate(nextProps, nextState) {
    console.log('feeds js componentWillUpdate ');
  }  

  componentDidUpdate(prevProps, prevState) {
    // console.log('feeds js componentDidUpdate , prevState: ', prevState.feedSelected, " this.state.feedSelected: ", this.state.feedSelected);
      
    const feedChanged = (prevState.feedSelected!==this.state.feedSelected);
    const isYoursFeed = (this.state.feedSelected === 'Yours');
    const isSearchFeed = (this.state.feedSelected === 'Search');

    // console.log("componentDidUpdate");
    if (feedChanged || isYoursFeed || isSearchFeed){
      // console.log("componentDidUpdate scrolltop cos: feedChanged || isYoursFeed || isSearchFeed? ", feedChanged, isYoursFeed, isSearchFeed);
      this.refInfiniteScrollContainer.scrollTop  = 0;
    }

  }  

  componentWillReceiveProps(nextProps) {
    console.log('feeds js componentWillReceiveProps ');

    if (nextProps.feedSelected !== this.state.feedSelected){
      console.log("NP!=S nextProps.feedSelected: ", nextProps.feedSelected, " this.state.feedSelected: ", this.state.feedSelected);
      this.setState({
        feedSelected: nextProps.feedSelected,
        hasMore : nextProps.quoteFeedHasMore
      })
      // console.log("repopulateQuotes cos feedSelected diffs, updated feedSelected state ", this.state.feedSelected, " while nextprops were: ", nextProps.feedSelected);
      // this.forceUpdate();
      // this.getNsetQuotes( 'All', nextProps.feedSelected );
    }
    // console.log("feedItemsS nextProps.user: ", nextProps.user, " this.state.user: ", 
    //   this.state.user, "  this.props.user: ", this.props.user);
    
    // if (nextProps.user !== this.state.user){
    //   this.setState({
    //     user: nextProps.user
    //   })
    // }
  }


  componentWillUnmount(){
    console.log('feeds js componentWillUnmount ');
  }

  // shouldComponentUpdate(){
  //     console.log('feeds js shouldComponentUpdate ');
  //     return false;
  // }

  // pad_quote_with_userRating( quote, isUsrQuote ){
  //   console.log("pad_quote_with_userRating isUsrQuote! ", quote," userRating is ", quote);

  //   if (quote.is_usr_q){
  //     console.log("isUsrQuote!, userRating is ", quote);
  //     return (quote.userRating || 0);
  //   }

  //   if (!this.state.user || !this.state.user.usr_quotes || this.state.user.usr_quotes.length===0 ) return 0;
  //   const matchedQuote = this.state.user.usr_quotes.find(uq=>uq.quote_id === quote.quote_id );
  //   if (matchedQuote){
  //     console.log("========= we have a matched quote!! rating is now:", matchedQuote.userRating);
  //     return matchedQuote.userRating;
  //   } else {
  //     return 0;
  //   }
  // }

  handleSearchKeyPressFeedItems = (ev) => {
    let searchText = (ev.target.value).trim();
    // searchText = searchText.toLowerCase();
    // if (searchText.length > 1){
    this.props.handleSearchKeyPressApp( searchText );
      
    this.setState({
      searchText
    });
  }



  fetchAdditionalHistoricData(){
    console.log("triggered fetching additional historic data.");
    if (this.state.loading) return;

    this.props.handleAdditionalDataFetch('below');
  }


  pullDownRefresh(){
    this.props.handleAdditionalDataFetch('above');
  }
  

  handleNewUsrQuote( newUsrQuote ){
    console.log("handlingNewUsrQuote: ", newUsrQuote.data);
    console.log("whereas other structure is: ", this.props.quoteFeed);

    this.props.updateUserState( newUsrQuote.data );
  }

  handle(searchText){
    this.props.handleSearchKeyPressApp();
    this.setState({
      searchText
    })
  }


  onMouseDown(ev){
  
    console.log("mousing down over ev.target ", ev.target, " ev.target: ", ev.target.tagName);
    const eCL = ev.target.classList;

    
    const isQuoteSpan = (ev.target.classList.contains('quoteSpan'));
    const parentIsQuoteSpan = (ev.target.parentNode.classList.contains('quoteSpan'));
    const quoteSpanFamily = (isQuoteSpan|| parentIsQuoteSpan);

    // if (eCL.contains('quoteSpan') || eCL.contains('theQuote') || eCL.contains('quoteTagsContainer')){
    // console.log("this.refInfiniteScrollContainer: ", this.refInfiniteScrollContainer);
    if ( quoteSpanFamily || eCL.contains('toolBtn') || 
    eCL.contains('tagContainer') || eCL.contains('quoteSpansOuter') ){
      this.scrollContainerScrollTop = this.refInfiniteScrollContainer.scrollTop;
      console.log("mousing down on an element meant for other functions");
      this.grabDown = false;
    } else {
      this.grabDown = true;
      // console.log("grab down over: ", this.grabDown );
      this.grabDownStartY = ev.clientY;
      this.scrollContainerScrollTop = this.refInfiniteScrollContainer.scrollTop;
    }
  }

  onMouseUp(ev){
    this.grabDown = false;
  }  

  onMouseLeave(){
    this.grabDown = false;
  }  

  onMouseMove(ev){

    if (this.grabDown){

      let mouseMovedY = ev.clientY - this.grabDownStartY;      
      // Scroll the element
      this.refInfiniteScrollContainer.scrollTop = this.scrollContainerScrollTop - mouseMovedY;
      
    }
  }



  render() {
    let { tagsSelected, feedSelected, user, quoteFeed, quoteFeedHasMore } = this.props;
    console.log("-------------- render feedItems nQuotes: ", quoteFeed.length, " hasMore? ", quoteFeedHasMore);

    console.log("--- state feedItems @ render: ", this.state);
    if (tagsSelected!==this.state.tagsSelected){
      console.log("P!=S * feeditems new tags render this.props.tagsSelected: ", tagsSelected, " this.state.tagsSelected: ", this.state.tagsSelected);
    }
    if (user!==this.state.user){
      console.log("P!=S * feeditems.js: render user state differs to props, props: ", user, " state: ", this.state.user );
    }
    if (feedSelected!==this.state.feedSelected){
      console.log("P!=S * feeditems.js: render feedSelected state differs to props, props: ", feedSelected, " state: ", this.state.feedSelected );
    }
    // console.log("this.state.user: ", this.state.user);
    const areQuotes = (this.props.quoteFeed && (this.props.quoteFeed.length > 0));

    const searchText = (feedSelected==='Search') ? this.state.searchText : null;


    const quoteFeedLength = this.props.quoteFeed.length || 0;

   
    return (
      <div id='feedItemsCont' className={feedSelected}>
        <FeedItemFiller />

        {(feedSelected==='Search' && (
          <SearchBar 
            handleSearchKeyPressFeedItems={this.handleSearchKeyPressFeedItems} 
            searchText={this.props.searchText}
          />
        ))}
        <div id='infiniteScrollContainer' 
          onMouseDown={this.onMouseDown}
          onMouseUp={this.onMouseUp}
          onMouseMove={this.onMouseMove}
          onMouseLeave={this.onMouseLeave}
          ref={c => (this.refInfiniteScrollContainer = c)}
          >
          <InfiniteScroll
            dataLength={quoteFeedLength}
            next={this.fetchAdditionalHistoricData}
            hasMore={quoteFeedHasMore}
            loader={<h4 ref={this.refLoader} style={{ width: '100%', textAlign: 'center', color: 'transparent'}}>Loading...</h4>}
            style={{ minWidth: '100%', display: 'flex', flexDirection: 'column', overflow:'visible'}} //To put endMessage and loader to the top.
            inverse={false}
            scrollThreshold={'150px'}

            scrollableTarget={'infiniteScrollContainer'}
            pullDownToRefresh={true}
            pullDownToRefreshThreshold={100}
            pullDownToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>&#8595; Pull down to refresh</h3>
            }
            releaseToRefreshContent={
              <h3 style={{ textAlign: 'center' }}>&#8593; Release to refresh</h3>
            }
            refreshFunction={this.pullDownRefresh}
            endMessage={
              <p style={{ textAlign: "center",  color: 'transparent' }}>
                <b>That's all the quotes for now</b>
              </p>
            }
          >
            {
              (this.state.feedSelected==='Yours' && (
              <FeedItem 
                  quote={{quote_vis:'', essence:''}}
                  key={`quoteEntry_000`} 
                  userUuid={this.state.userUuid}
                  isQuoteEntry={true}
                  tagsSelected={this.props.tagsSelected}
                  handleNewUsrQuoteFeedItems={this.props.handleNewUsrQuoteApp}
                  role={this.props.role} />
                ))
            }
            {
              areQuotes &&
              (
              this.props.quoteFeed.map((quote,i) => (
                <FeedItem 
                  quoteId={quote.quote_id} 
                  usrQuoteId={quote.usr_quote_id}
                  key={`${quote.quote_id}_${i}`} 
                  itemI={i} 
                  quote={quote} 
                  tagsSelected={this.state.tagsSelected}
                  userRating={quote.userRating}
                  userUuid={this.state.userUuid}
                  isQuoteEntry={false}
                  feedSelected={feedSelected}
                  isUsrQuote={quote.is_usr_q}
                  searchText={searchText}
                  role={this.props.role}
                  clickHandlerFeedItems={this.clickedCuratorTools} 
                  updateCacheRating={this.props.updateCacheRating} 
                  updateQuoteHandlerFeedItems={this.props.updateQuoteHandlerApp}
                />
              ))
              )
            }

          </InfiniteScroll>   
        </div>
      </div>
    )}
}

export default FeedItems;
