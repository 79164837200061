import firebase from './utils/firebase.js'

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import './fonts/MontserratAlternates-Medium.woff2';

import App from './app/app.js'

if (process.env.NODE_ENV !== 'development') {
  console.log = () => {}
}

ReactDOM.render(
    <App />,
  document.getElementById('react-root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();