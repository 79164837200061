
import React from 'react';
import './toolBtn.css'


class ToolBtn extends React.Component {
  constructor(props) {
    super(props);
    this.click_toolBtn = this.click_toolBtn.bind(this);
  }


  componentDidMount() {

  }

  click_toolBtn(ev){
    console.log("click_toolbtn: ", ev);
    if (this.props.clickHandlerCurateBtn){
      this.props.clickHandlerCurateBtn(ev);

    }
  }


  render() {

    const { btnText, quotePos, quoteId, active,  bgOpacity } = this.props;
    const centerAlign = (this.props.centerAlign);
    const classNames = (active) ? 'toolBtn active' : 'toolBtn';
    // const bgText = (active) ? '#fff' : `rgba(154,84,48,${bgOpacity})`;
    const bgText = (active) ? 'var(--nav-selected-bg)' : `rgba(154,84,48,${bgOpacity})`;

    // const bgText = (active) ? '#000' : `rgba(154,84,48,${bgOpacity})`;

    // const bgText =  `rgba(154,84,48,${bgOpacity})`;

    // console.log(`toolbtn ${btnText} active? ${active} `);
    return (
      <div className="toolBtnOuter" data-curator-btn-val={btnText}>
        <button className={classNames} data-curator-btn-val={btnText} style={{'background':bgText}}  onPointerDown={this.click_toolBtn}>
            <span data-curator-btn-val={btnText} >{btnText}</span>
        </button>
      </div>
      )}
}

export default ToolBtn;
