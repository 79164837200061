
import React from 'react';
import './navItem.css'
import '../../../feedItems/searchbar/searchbar.css';


class NavItem extends React.Component {
  constructor() {
    super();
    
  }


  componentDidMount() {

  }


  render() {

    const { text, feedSelected } = this.props;


    let feedName = text.trim();

    const isSearchNav = (feedName==='Search');

    const label = (text==="Search") ? '' : text.trim();
    
    const active = (feedSelected===feedName)

    let navButtonClass = 'navButton';
    navButtonClass += (isSearchNav) ? ' searchBtn' : '';

    let navButtonOuterClass = 'navButtonOuter';
    navButtonOuterClass += (isSearchNav) ? ' searchNavItem' : '';
    navButtonOuterClass += (active) ? " active" : "";

    return (
      <div className={navButtonOuterClass} onClick={this.props.clickHandlerNav}  data-index-number={this.props.pos} data-feed-name={feedName}>
        <button className={navButtonClass} data-index-number={this.props.pos} data-feed-name={feedName}>
          <span data-index-number={this.props.pos}  data-feed-name={feedName}>{label}</span>
        </button>
      </div>

    );
  }
}

export default NavItem;
