
import React from 'react';

import './searchSpan.css';

import { select_all_text_in_el } from '../../../../../../utils/utils.js';


class SearchSpan extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   startSpanTxt: props.startSpanTxt,
    //   essence: props.essence,
    //   endSpanTxt: props.endSpanTxt,
    // };
    // this.inputChanged = this.inputChanged.bind(this);
    this.onClickQuoteEntry = this.onClickQuoteEntry.bind(this);
    this.labelTextToHighlight = this.labelTextToHighlight.bind(this); 
    this.blankText = '                                                                                                   ';
  }


  componentDidMount() {
  }

  compute_essence_indexes(visText, essenceTxt) {
    let essenceStartI = visText.indexOf(essenceTxt);
    let essenceEndI = essenceStartI + essenceTxt.length;
    return [essenceStartI, essenceEndI];
  }



  cancel_ev(ev) {
    // ev.stopPropagation();
    ev.preventDefault();
    // return false;
  }

  onPointerDown(ev) {
    console.log("onPointerDown: ", ev);

    ev.preventDefault();
    // ev.stopPropagation();
    return false;
  }

  onClickQuoteEntry(ev) {
    console.log("In quote spans: ", ev.target, " this.props.isQuoteEntry: ", this.props.isQuoteEntry);
    if (!this.props.isQuoteEntry) return;
    select_all_text_in_el(ev.target);
    ev.target.innerText = ev.target.innerText.trim();
    ev.target.focus();
  }



  labelTextToHighlight( spanText, searchText ){

    const text = spanText.toLowerCase();

    const indexOfSearch = text.indexOf(searchText.trim().toLowerCase());

    let highlightClass = '';

    let highlightArray = [];

    if (!Number.isInteger(indexOfSearch) || indexOfSearch===-1){
      highlightArray.push({spanText,highlightClass});
      return highlightArray;
    } 

    let endOfSecondSegment;
    
    if (indexOfSearch===0){

      highlightArray = [{
        text: spanText.substring(0, indexOfSearch+searchText.length),
        highlightClass: 'highlightThis'
      }];

      endOfSecondSegment = searchText.length;

    } else {
      endOfSecondSegment = indexOfSearch + searchText.length;
      highlightArray = [{
          text: spanText.substring(0, indexOfSearch),
          highlightClass: 'nonHlQuoteSpan'
        },
        {
          text: text.substring(indexOfSearch, endOfSecondSegment ),
          highlightClass: 'highlightThis'
        }
      ];
    }

    if (endOfSecondSegment < text.length){
      highlightArray.push({
        text: spanText.substring( endOfSecondSegment),
        highlightClass: 'nonHlQuoteSpan'
      })
    }

    console.log("aa results: txtToSearch: ", highlightArray);

    return highlightArray;
  

  }
  
  render() {

    let { spanType, spanText, searchText } = this.props;

    // console.log("searchText: ", searchText, " spanText: ", spanText, " spanType: ", spanType);
    if (!spanText) return '';

    let highlightArray = [{text: spanText, highlightClass: ''}];

    if (searchText){
      highlightArray = this.labelTextToHighlight( spanText, searchText );
    }
    // console.log("quotespan props ", this.props);
    // console.log("contentEditableVal: ", contentEditableVal);
    const classNames = `quoteSpan ${spanType}`;

  

    // const editable;

    return (
      <span className={classNames}>
        {
          highlightArray.map((wordSplit,i)=>(
            <span className={wordSplit.highlightClass} key={`wrdSplit${i}`}>
              {wordSplit.text}
            </span>
          ))
        }
      </span>
    );
  }
}

export default SearchSpan;
