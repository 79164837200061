import { memo } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import '../../../../tag/tag.css'
const style = {
  cursor: 'move',
}

export const DndTag = memo(function DndTag({ id, text, moveDndTag, findDndTagById, findDndTagsByText, 
    addTag, delTag, getDndTags, editable, isLast, draggable, isTagSelected }) {
  const originalIndex = findDndTagById(id).index
  const [{ isDragging }, drag] = useDrag(
    () => ({
    type: 'dndTag',
    item: { id, originalIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      const { id: droppedId, originalIndex } = item
      const didDrop = monitor.didDrop()
      if (!didDrop) {
        moveDndTag(droppedId, originalIndex)
      }
    },
  }),
  [id, originalIndex, moveDndTag],
  )
  // const ref = useRef(null)
  const [, drop] = useDrop(
    () => ({
    accept: 'dndTag',
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: overIndex } = findDndTagById(id)
        moveDndTag(draggedId, overIndex)
      }
    },
  }),
  [findDndTagById, moveDndTag],
 
)

function onBlurHandlerTag(ev) {
  let tagText = ev.target.innerText;
  let tagOfInterest = ev.target;
  const isLast = ev.target.dataset.isLast;


  // console.log("in on blur handler: ", tagOfInterest, tagText, isLast);
  if (isLast==='true'){
    // console.log("islast, so checking if should add a new tag or not")
    if (tagText===''){
      tagOfInterest.innerText = '+';
    } else {
      tagOfInterest.dataset.isLast = false;
      addTag(tagText)
    }
  } else if (tagText===''){
    // console.log("in delete tag ");
    delTag(id, originalIndex)
  }

  // console.log("isLastVal: ", isLast);

  // ensure_one_empty_tag( tagOfInterest, tagText);
}

function ensure_one_empty_tag(tagOfInterest, tagText){
  let retrievedEmptyTags = findDndTagsByText('');
  let retrievedAddTags = findDndTagsByText('+');
  // console.log("retrivedAddTags are: ", retrievedAddTags);
  
  if (!retrievedEmptyTags){

  }
  if (!retrievedAddTags) {
    addTag()
  } 
}

function onKeyPressHandlerTag(ev) {
  const keycode = ev.charCode || ev.keyCode;
  // console.log("in key press handler, keycode ", keycode)
  const tagText = ev.target.innerText;

  if (keycode === 13) { 
    ev.preventDefault();
    ev.target.blur();
    return false;
  } 
  else if (keycode === 8) { 
    ev.preventDefault();
    if (tagText === ''){
      // delTag(ev.target);
    }
    return false;
  } 
}

function onFocusHandlerTag(ev) {
  console.log('in onFocusHandlerTag');

  const tagText = ev.target.innerText;
  console.log("ev.target: ", ev.target, " innerText ", tagText);
  if (tagText === '+'){
    ev.target.innerText = '';
  }
}

const opacity = isDragging ? 0 : 1
const classNames = (isTagSelected) ? 'tagContainer selected' : 'tagContainer';
return (
  <div className={classNames}
    ref={(node) => draggable && drag(drop(node))} 
    data-is-last ={isLast}
    data-index ={originalIndex}
    style={{ ...style, opacity }}
    contentEditable={(editable)} suppressContentEditableWarning={true}
    onBlur={onBlurHandlerTag}
    onKeyPress={onKeyPressHandlerTag}
    onFocus={onFocusHandlerTag}
    onPaste={(e) => {
        e.preventDefault();
        const text = e.clipboardData.getData("text");
        document.execCommand("insertText", false, text);
    }}>
    {text}
  </div>
)
})
