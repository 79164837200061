
import React from 'react';
import './nav.css'
import NavItem from './navItem/navItem'

class Nav extends React.Component {
  constructor() {
    super();
 

  }


  componentDidMount() {

  }


  render() {

    const {navItems, feedSelected} = this.props;
    console.log("rendering nav: ", navItems);
    // const nNavItems = navItems.length + 1;

    return (
      <nav id="contentFeedHeaderMenuNav">
        <div id="headerMenuNavItems">
          {
            navItems.map((item,i) => (
              <NavItem feedSelected={feedSelected} text={item} key={item.trim()} pos={i+1} clickHandlerNav={this.props.clickHandlerApp}  />
            ))
          }
        </div>
      </nav>
    );
  }
}

export default Nav;
