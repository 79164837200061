

export const isElementInViewport = (el) => {
  var rect = el.getBoundingClientRect();

  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}

export const get_sess_obj = () => {

  console.log(navigator);
  const nav = (({ platform, vendor, languages, userAgent }) => ({ platform, vendor, languages, userAgent }))(navigator);
  const winScreenExtra = (({ screenLeft, screenTop, screenX, screenY, outerHeight, outerWidth, innerHeight, innerWidth }) => ({ screenLeft, screenTop, screenX, screenY, outerHeight, outerWidth, innerHeight, innerWidth }))(window);
  const winScreenMain = (({ availHeight, availWidth, orientation }) => ({  availHeight, availWidth, orientation }))(window.screen);
  winScreenMain.orientation = (winScreenMain.orientation) ? winScreenMain.orientation.type : null;
  const winScreen = { 
    ...winScreenExtra,
    ...winScreenMain
  };
  
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  const sessObj = {
    nav,
    winScreen,
    timezone
  }
  
  console.log("window: ", window);
  console.log("nav: ", nav);

  return sessObj;
}

// export default {get_user_sess_obj, isElementInViewport};