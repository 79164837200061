
import React from 'react';
import './user.css';



import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
const functions = getFunctions();
const newLocal = 'localhost';
if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, newLocal, 5001);
}
// const initSess = httpsCallable(functions, 'initSess');


class User extends React.Component {
  constructor(props) {
    super(props);

    const { user, userUuid }  = props;
 
    const loggedIn = false;
    const role = "nlp";
    
    this.state = {
      user,
      loggedIn,
      userUuid,
      role
    }

  }

  componentDidMount() {
    this.resolveInitSessPromise();
  }

  async resolveInitSessPromise(){
    // this.usrPromise
    //   .then(async (usr) => {
    //     usr = usr.data;
    //     console.log("usr returned: ", usr);
    //     this.props.usrUpdated( usr );
    //     // if (usr){
    //     //   this.updateUsrState( usr );
    //     // } else {
    //     //   // this.repopulateQuotes();
    //     //   // await this.delay(3000);
    //     // }
    //   });
  }

  componentWillUpdate(nextProps, nextState) {
  }  

  componentDidUpdate(prevProps, prevState) {
  }  

  componentWillReceiveProps(nextProps) {
  }

  render() {


    return (
      <div className='profileContainerInner hidden'>
        <button>
      {
      (!this.state.isLoggedIn && (
        'Signed in'
      ))
      }
      {
      (this.state.isLoggedIn && (
        'Sign in'
      ))
      }
      </button>
      </div>
    )
  }
}

export default User;
