import React from 'react';
import './app.css';
import '../components/sidebar/sidebar.css';
import '../components/header/header.css';

import FeedItems from '../components/feedItems/feedItems';

import QuotesDat from '../dat/quotesDat.js';
import UserDat from '../dat/userDat.js';

import { clearAllCache } from "../utils/cacheUtils.js";

import Nav from '../components/header/nav/nav';
import TagsList from '../components/tagsList/tagsList';
import User from '../components/header/user/user';

import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';



class App extends React.Component {
  constructor(props) {
    super(props);

    // clearAllCache();

    const role = (process.env.NODE_ENV !== 'development') ? 'customer' : 'nlp';

    this.UD = new UserDat( role );

    this.navItems = ['Fresh', 'Popular', 'Tweeted', 'Yours', 'Search'];

    this.tagsAvailable = ['life', 'thought', 'wisdom', 'society',
      'inspiration', 'writing', 'divine', 'love', 'technology'];

    let tagsSelected;

    if (!props.tagsSelected) {
      tagsSelected = this.tagsAvailable;
    }
    this.QD = new QuotesDat( this.navItems, this.tagsAvailable, role );
    this.QD.second_stage_init( this.role, tagsSelected, null, this.UD.usrQuotes );

    this.state = {
      quoteFeed: this.QD.quoteFeed,
      feedSelected: this.QD.feedSelected,
      tagsSelected,
      tagsAvailable: this.tagsAvailable,
      userUuid: this.UD.uuid,
      role
    }

    this.navItemClickedApp = this.navItemClickedApp.bind(this);
    this.tagItemClickedApp = this.tagItemClickedApp.bind(this);
    this.refreshQuoteFeed = this.refreshQuoteFeed.bind(this);

    this.update_quote = this.update_quote.bind(this);
    this.insert_new_usr_quote = this.insert_new_usr_quote.bind(this);
    this.set_userRating = this.set_userRating.bind(this);
    this.fetch_additional_data = this.fetch_additional_data.bind(this);
    this.resolve_quotesFeed_prom = this.resolve_quotesFeed_prom.bind(this);
    this.update_search_results = this.update_search_results.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.clicked_header = this.clicked_header.bind(this);

    this.refSidebarContainer = React.createRef();

    this.nHeaderClicks = 0;
  }



  async update_quote(values, quoteId, usrQuoteId) {
    if (Number.isInteger(quoteId)) {
      const propertiesToSave = await this.QD.update_quote(values, quoteId);
      this.QD.save_properties(propertiesToSave);

    } else if (usrQuoteId) {
    }

    this.refreshQuoteFeed(this.state.feedSelected, this.state.tagsSelected);
  }



  async insert_new_usr_quote(text, author) {
    await this.UD.insert_new_usr_quote(text, author);

    this.QD.update_yours_feed(this.UD.usrQuotes);

    this.refreshQuoteFeed(this.state.feedSelected, this.state.tagsSelected);
  }



  async set_userRating(ratedQuoteObj) {
    this.UD.set_rating( ratedQuoteObj );

    let skipDb = false;
    const propertiesToSave = await this.QD.update_quote(ratedQuoteObj, ratedQuoteObj.quote_id, skipDb);
    this.QD.save_properties(propertiesToSave);


    this.QD.update_yours_feed(this.UD.usrQuotes);
  }



  navItemClickedApp(ev) {
    console.log("navItemClickedApp: ", this.state.user);

    const feedSelected = ev.target.dataset.feedName;
    const feedNameChanged = (feedSelected !== this.state.feedSelected);
    // console.log("cw: navItemClickedApp, new feedname? ", feedNameChanged, " clicked: ", feedNameClicked, " this.state.feedSelected: ", this.state.feedSelected);
    if (!feedNameChanged) return;

    this.refreshQuoteFeed(feedSelected, this.state.tagsSelected)

    // console.log("this.user: ", this.state.user);
    // console.log("cw: navItemClickedApp this.QD.quoteFeed ", this.QD.quoteFeed);
    if (feedSelected === "Search") {
    } else {
    }
  }



  tagItemClickedApp(ev) {
    const tagTextClicked = ev.target.dataset.tagText;

    let tagsSelected = [...this.state.tagsSelected];
    const tagInStateIndex = tagsSelected.indexOf(tagTextClicked)

    if (tagInStateIndex >= 0) {
      tagsSelected.splice(tagInStateIndex, 1);
    } else {
      tagsSelected.push(tagTextClicked);
    }
    this.refreshQuoteFeed(this.state.feedSelected, tagsSelected);
  }



  fetch_additional_data(additional) {

    this.refreshQuoteFeed(this.state.feedSelected, this.state.tagsSelected, additional);
  }



  update_search_results(searchText) {
    searchText = searchText.toLowerCase().trim();
    this.QD.update_searchResults_feed( searchText );
    this.setState({
      searchText
    })

    this.populate_quote_feed(this.state.feedSelected, this.state.tagsSelected);
    // this.QD.savePropertyToCache('feedsCache');
  }



  refreshQuoteFeed(feedSelected, tagsSelected, additional = null) {

    // console.log('refreshQuoteFeed feedSelected, ', feedSelected, ' tagsSelected ', tagsSelected, ' additional: ', additional, ' this.UD.usrQuotes: ', this.UD.usrQuotes);

    this.QD.set_quote_feed(feedSelected, tagsSelected, additional,
      this.UD.usrQuotes);

    this.populate_quote_feed(feedSelected, tagsSelected);
  }



  populate_quote_feed(feedSelected, tagsSelected) {
    this.QD.quoteFeed.then(async () => {
      const quoteFeed = await this.QD.quoteFeed;
      console.log("JSONIFIED")
      console.log(JSON.stringify(quoteFeed));
      const nQuotes = quoteFeed.length;
      // console.log("wu: cw: resolved quoteFeed: ", quoteFeed);
      this.setState({
        ...this.state,
        quoteFeed,
        tagsSelected,
        feedSelected,
        nQuotes,
      });
      this.QD.update_all_caches();

      const populatingCacheFeedFrom = 'populatingCacheFeedFrom';
      this.QD.update_all_caches(populatingCacheFeedFrom);
    });
  }



  toggleSidebar(ev) {
    // console.log("");console.log("");console.log("");console.log("");
    // console.log("ev: ", ev);
    if (this.currWindowWidth < 730) {
      // console.log("because size is less than 848, toggling clickedSmallScreenOverlay class");
      // console.log("this.refSideBarContainer.classList: ", this.refSideBarContainer.classList);
      if (this.refSideBarContainer.classList.contains('clickedSmallScreenOverlay')) {
        // console.log('REMOVING ');
        this.refSideBarContainer.classList.remove('clickedSmallScreenOverlay');
      }
      else {
        // console.log('ADDING ')
        this.refSideBarContainer.classList.add('clickedSmallScreenOverlay');
      }
    } else {
      this.refSideBarContainer.classList.remove('clickedSmallScreenOverlay');
    }
  }



  handleResize(ev) {
    this.currWindowWidth = window.innerWidth;
    if (this.currWindowWidth >= 730) {
      this.refSideBarContainer.classList.remove('clickedSmallScreenOverlay');

    }
  }



  componentDidMount() {
    this.currWindowWidth = window.innerWidth;
    this.feedAndSideBarEl = document.getElementById('theFeedAndSidebar');

    
    window.addEventListener("resize", this.handleResize);

    this.resolve_quotesFeed_prom();


  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }


  async resolve_quotesFeed_prom() {
    this.QD.quoteFeed.then((quoteFeed) => {
        
      this.setState({
        quoteFeed,
        quoteFeedHasMore: this.QD.quoteFeedHasMore
      });
      this.QD.update_all_caches('resolve_quotesFeed_prom');

    }).catch((err) => {
      console.log("uuser: app componentDidMount this.quoteFeed err ", err);
    })
  }


  updateUsrStateNewQuote(newQuoteDat) {
    let usrQuotes = [...this.state.user.usrQuotes];
    usrQuotes = [newQuoteDat].concat(usrQuotes);
    this.setState({
      user: {
        ...this.state.user,
        usrQuotes
      }
    });
  }

  async clicked_header(){
    this.nHeaderClicks += 1;
    if (this.nHeaderClicks % 5 === 0){
      console.log("prior this.state.role: ", this.state.role);
      const newRole = (this.state.role==='customer') ? 'nlp' : 'customer';
      this.setState({
        role: newRole
      })
      await this.QD.retrieveCache( this.navItems, this.tagsAvailable, newRole );
      await this.QD.second_stage_init( newRole, this.state.tagsSelected, null, this.UD.usrQuotes );
      this.resolve_quotesFeed_prom();
    }
  }



  render() {

    console.log('== APP render: ', this.state);

    return (

      <div id="appContainer">

        <div id="theHeaderContainer">
          <div id="topHeaderContainerOuter">
            <div id="topHeaderContainer">
              <div id="topHeaderLogo" onClick={this.clicked_header}>
                EssenceOfTheQuote
                <span className='beta'>Beta</span>

              </div>
              <div className="profileContainer">
                <User user={this.state.user} userUuid={this.state.userUuid} usrUpdated={this.handle_user_updated} />
              </div>
            </div>
          </div>
          <div id="navContainer">
            <Nav feedSelected={this.state.feedSelected} navItems={this.navItems} clickHandlerApp={this.navItemClickedApp} />
          </div>
        </div>

        <div id="theFeedAndSidebar">

          <div id="theFeedContainer">
            <div id="theFeedContents">

              {(
                <DndProvider backend={HTML5Backend}>
                  <FeedItems
                    feedSelected={this.state.feedSelected}
                    user={this.state.user}
                    tagsSelected={this.state.tagsSelected}
                    quoteFeed={this.state.quoteFeed}
                    quoteFeedHasMore={this.state.quoteFeedHasMore}
                    userUuid={this.state.userUuid}
                    role={this.state.role}
                    searchText={this.state.searchText}
                    handleNewUsrQuoteApp={this.insert_new_usr_quote}
                    updateQuoteHandlerApp={this.update_quote}
                    handleAdditionalDataFetch={this.fetch_additional_data}
                    handleSearchKeyPressApp={this.update_search_results}
                    updateCacheRating={this.set_userRating}
                  />
                </DndProvider>
              )}
            </div>
          </div>

          <div id="sideBarContainer"
            ref={c => (this.refSideBarContainer = c)}>

            <div id="sideBarContentContainer" onClick={this.toggleSidebar}>
              <div id="sideBarContentItems">
                <div className="sideBarContentItemContainer">
                  <div id="tagsSideOuter" className="sideBarContentItem">
                    <TagsList clickHandlerApp={this.tagItemClickedApp}
                      tagsSelected={this.state.tagsSelected}
                      tags={this.state.tagsAvailable}
                      isSidebar={true} />
                  </div>
                </div>
              </div>

              
            </div>


          </div>
        </div>
        <div id="footerContainer">
                <span><a href="privacy.html">Privacy policy</a></span>
                <span>© 2022</span>
              </div>
      </div>

    )
  }
}

export default App;
