
// import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
import { saveCache, parseObj, loadAndParse, clearCache, clearAllCache } from "../utils/cacheUtils.js";
import { are_these_two_arrays_of_objs_eq } from "../utils/utils.js";
import { quotesFromFile } from "./quotesFromFile.js"

const newLocal = 'localhost';

// const functions = getFunctions();
// if (process.env.NODE_ENV === 'development') {
//   connectFunctionsEmulator(functions, newLocal, 5001);
// }
// const queryQuotes = httpsCallable(functions, 'queryQuotes');

const consolePrintQuotes = (quotes, heading, keys=[], nQsToPrint=4) => {
  console.log("======= QUOTES! ", heading)
  if (!quotes) return;

  quotes.map( (q, i) => {
    if (i < nQsToPrint) {
      let qStr = q.quote_id + ' ' + q.quote_vis.substring(0, 20);
      qStr += (keys[0]) ? q[keys[0]] : '';
      qStr += (keys[1]) ? q[keys[1]] : '';
      console.log(qStr);
    } else {
      return null;
    }
  });
};


class QuotesDat {
  constructor( feedCategories, tagsAvailable, role ){
    this.tagsAvailable = tagsAvailable;
    this.feedCategories = feedCategories;

    // constants
    this.maxAllCacheSize = 10000;
    this.pagingSize = 20;


    this.update_quote_in_cache = this.update_quote_in_cache.bind(this);
    this.update_searchResults_feed = this.update_searchResults_feed.bind(this);
    this.second_stage_init = this.second_stage_init.bind(this);

    this.retrieveCache( feedCategories, tagsAvailable, role );
    // this.qsByTagsPool = [];

  }



  retrieveCache( feedCategories, tagsAvailable, role ){
    this.localStorageCachesToUpdate = [];

    const cachedRole = JSON.parse(localStorage.getItem( 'role' ));
    this.role = cachedRole || null;

    if (role!==this.role){

      clearAllCache();

      this.role = role;
      this.localStorageCachesToUpdate.push('role');


    }

    // load quote arrays from localstorage
    this.allCachedQs = loadAndParse('allCachedQs') || [];

    this.quoteFeed = loadAndParse('quoteFeed') || [];

    this.cachedQsOfTags = loadAndParse('cachedQsOfTags') || {};
    // if you're not updating this what's the point of caching it?
    this.qsByTagsPool = loadAndParse('qsByTagsPool') || {};
    this.feedsCache = loadAndParse('feedsCache') || {};


    // Load non quote arrays.
    this.feedSelected = JSON.parse(localStorage.getItem( 'feedSelected' )) || 'Fresh';
    this.tagsSelected = tagsAvailable || JSON.parse(localStorage.getItem( 'tagsSelected' ));

    this.endIndex = JSON.parse(localStorage.getItem( 'endIndex' )) || Number(0);


    this.tagsAvailable = tagsAvailable;

  }



  async reset_cache(){
    await clearAllCache();
    this.retrieveCache( this.tagsAvailable, this.feedCategories, this.role );
  }



  savePropertyToCache(cacheName) {
    saveCache(cacheName, this[cacheName]);
  }



  save_properties(properties) {
    properties.map((property)=>{
      this.savePropertyToCache(property);
    });
    console.log("((()))) saved properties: ", properties, " this.role: ", this.role);
  }



  second_stage_init(role, tagsSelected, feedSelected, usrQuotes){
    //   // this.role = role;
    //   console.log("zzz role change detected, from ", this.role, " to ", role ," resetting cache");
    //   console.log("");
    //   this.reset_cache();
    //   console.log("zzz role change detected, from ", this.role, " to ", role ," resetting cache");

    //   this.localStorageCachesToUpdate.push('role');
    //   forceRefresh=true;

    // }

    // usrQuotes.then((usrQuotes)=>{
      
    // })

    this.set_quote_feed(feedSelected, tagsSelected, null, usrQuotes);

    // TODO
    // this.cache_other_feeds()
    this.lazily_ensure_cache_and_db_are_consistent()

    console.log('cw: after prom ');
  }





  async set_quote_feed( feedToSelect, tagsSelected, additional=null, usrQuotes, forceRefresh=false ){
    this.usrQuotes = this.usrQuotes || usrQuotes;
  
    if (feedToSelect){
      this.feedSelected = feedToSelect;
      this.localStorageCachesToUpdate.push('feedSelected');
    }
    if (tagsSelected){
      this.tagsSelected = tagsSelected;
      this.localStorageCachesToUpdate.push('tagsSelected');
    }

    this.tagsPoolStr = this.tagsSelected.join('_');
    this.tagsPoolAndFeedStr = this.tagsPoolStr + "_" + this.feedSelected;

    const isSearch = (feedToSelect==='Search');
    if (isSearch){
      if (this.feedsCache[ this.tagsPoolAndFeedStr ]){
        // this.feedsCache[ this.tagsPoolAndFeedStr ] = this.feedsCache[ this.tagsPoolAndFeedStr ];
      } else {
        const forceUpdate = true;
        this.update_searchResults_feed(this.searchText, null, forceUpdate); 
        return;
      }
    }

    if ( (!this.feedsCache || !this.feedsCache[ this.tagsPoolAndFeedStr ])){
      this.quoteFeed = this.merge_quotes_from_db_with_cache( this.role, this.tagsSelected, usrQuotes ).then( () => {
        return this.get_quote_feed( this.feedSelected, this.tagsSelected, additional, forceRefresh );
      });
 
      this.localStorageCachesToUpdate.push('quoteFeed');

    } else {
      this.lazily_ensure_cache_and_db_are_consistent();
      // console.log("uuser: did NOT refresh quote feed, relied on cache of ", this.tagsPoolAndFeedStr );
      // console.log("uuser: cw: feed cache: ", this.feedsCache );

      // console.log("cwuu: ", [...this.feedsCache[ this.tagsPoolAndFeedStr ]]);
      this.set_quoteFeed_endIndex( additional );
      this.quoteFeed = Promise.resolve([...this.feedsCache[ this.tagsPoolAndFeedStr ]].slice(0, this.endIndex));



      // return quoteFeed;
    }
  }



  update_yours_feed( usrQuotes ){
    this.usrQuotes = usrQuotes;

    console.log("uuser: YOURS updating yours feed: ", usrQuotes, " for curr feed: ", this.tagsPoolStr, " this.qsByTagsPool[ this.tagsPoolStr ]: ", this.qsByTagsPool[ this.tagsPoolStr ].length);
    const yoursFeedTagsPoolStr = this.tagsPoolStr + "_Yours";
    
    if (this.qsByTagsPool[ this.tagsPoolStr ]){
      this.feedsCache[ yoursFeedTagsPoolStr ] = this.merge_archived_quotes_with_usr_quotes( this.qsByTagsPool[ this.tagsPoolStr ] );
      this.savePropertyToCache('feedsCache');
      console.log("uuser: YOURS updating yours feed TO PRODUCE  ", this.feedsCache[ yoursFeedTagsPoolStr ]);
      // console.log("yoursss feedsCache: ", this.feedsCache[ this.tagsPoolAndFeedStr ]);
      // this.quoteFeed = this.merge_quotes_from_db_with_cache( this.role, this.tagsSelected, usrQuotes ).then( () => {
      //   return this.get_quote_feed( this.feedSelected, this.tagsSelected, this.feedRange );
      // });
      // console.log("cw: this.quoteFeed: ", this.quoteFeed);
      // this.localStorageCachesToUpdate.push('quoteFeed');
    }
  }



  async update_searchResults_feed(searchText, additional, forceUpdate=false){
    const searchTextChanged = (this.searchText!==searchText);

    let results = []

    if (searchTextChanged || forceUpdate){
      this.searchText = (searchText) ? searchText.toLowerCase().trim() : null;
      if (!this.qsByTagsPool[this.tagsPoolStr]){
        await this.pool_together_quotes_with_tags( this.tagsSelected )
      }
      // }
      // console.log("received searchText: ", searchText);
      // prioritise matches within essence text.
      const cachedQsToSearch = this.qsByTagsPool[this.tagsPoolStr];
      cachedQsToSearch.map( (q) => {
        if (q.essence && q.essence.toLowerCase().includes(searchText)){
          results.push(q);
        }
      });


      // if few results, add some matching the quote_vis text
      if (results.length < 5){
        cachedQsToSearch.map( (q) => {
          if (q.quote_vis.toLowerCase().includes(searchText)) {
            results.push(q);
          }
        });
      }
    }

    this.tagsPoolAndFeedStr = this.tagsPoolStr + "_Search";

    this.feedsCache[ this.tagsPoolAndFeedStr ] = [...results];

    this.set_quoteFeed_endIndex( additional );
    this.quoteFeed = Promise.resolve([...this.feedsCache[ this.tagsPoolAndFeedStr ]].slice(0, this.endIndex));

    this.quoteFeed = Promise.resolve(results);
    console.log("results: ", results);
    // console.log("searched: ", cachedQsToSearch, " returned this.quoteFeed: ", returnedQs);
    
  }



  set_quoteFeed_endIndex( additional ){
    this.endIndex = Number(0) || Number(this.endIndex);
    // this.endIndex = (additional==='below') ? this.endIndex + 
    const feedLength = this.feedsCache[ this.tagsPoolAndFeedStr ].length;

    const idealEndIndex = (this.endIndex+this.pagingSize);
    this.endIndex = Math.min( idealEndIndex, feedLength );
    this.quoteFeedHasMore = ( this.endIndex < feedLength );
  }



  async update_all_caches(additionalHeaderTxt){
    this.localStorageCachesToUpdate = [...new Set(this.localStorageCachesToUpdate)];

    this.localStorageCachesToUpdate.map((cache)=>this.savePropertyToCache(cache));
    this.localStorageCachesToUpdate = [];
  }



  async lazily_ensure_cache_and_db_are_consistent(){
    const additional = 'above';
    this.merge_quotes_from_db_with_cache( this.role, this.tagsSelected, this.usrQuotes ).then( () => {
      return this.get_quote_feed( this.feedSelected, this.tagsSelected, additional );
    });
  }



  async get_quote_feed( feedToSelect, tagsSelected, additional ){
    // console.log('yoursss cw: making quote feed ', feedToSelect,' tagsSelected ', tagsSelected );
    // console.log("get_quote_feed additional: ", additional);
    if ( additional || !this.qsByTagsPool || !this.qsByTagsPool[ this.tagsPoolStr ] || this.qsByTagsPool[ this.tagsPoolStr ].length===0 ){
      await this.pool_together_quotes_with_tags( tagsSelected );
    }
    // console.log("get_quote_feed this far: ", this.feedsCache, " this.tagsPoolAndFeedStr: ", this.tagsPoolAndFeedStr);
    if ( additional || !this.feedsCache || !this.feedsCache[ this.tagsPoolAndFeedStr ]  || this.feedsCache[ this.tagsPoolAndFeedStr ].length===0 ){
      this.sort_quotes_for_feed( feedToSelect );
    }

    // console.log("cw feed : ", feedToSelect, this.feedsCache[ this.tagsPoolAndFeedStr ] );
    this.set_quoteFeed_endIndex( additional );
    
    // return [...this.feedsCache[ this.tagsPoolAndFeedStr ]].slice(0,this.endIndex);
    return [...this.feedsCache[ this.tagsPoolAndFeedStr ]].slice(0,this.endIndex);
  }



  async get_quotes_from_db(){
  }



  async merge_quotes_from_db_with_cache( role, tagsToDivy, usrQuotes ){
    // if (this.allCachedQs.length > 0) return;
    role = role || this.role;
    tagsToDivy = tagsToDivy || this.tagsToDivy;
    usrQuotes = usrQuotes || this.usrQuotes;


    let lastUpdatedQuote;

    if ((this.allCachedQs && this.allCachedQs.length > 0)){
      lastUpdatedQuote = [...this.allCachedQs].sort((a, b) => 
          a.updated - b.updated)[this.allCachedQs.length - 1];
    }
    console.log("cached quotes: ", this.allCachedQs, " lastUpdatedQuote: ", lastUpdatedQuote);

    console.log("lastUpdatedQuote: ", lastUpdatedQuote);

    // lastUpdatedQuote = [...this.allCachedQs].sort(( b, a) => a.updated - b.updated)[this.allCachedQs.length - 1];

    // lastUpdatedQuote.updated = new Date(lastUpdatedQuote.updated.getTime() + 1000);
    // console.log("wu: added 1000 to ", lastUpdatedQuote.updated, " giving ", lastUpdatedQuote.updated);

    const queryStr = {
      statement: 'select',
      role,
      isForCache: 'true',
      lastUpdatedQuote,
      limit: null
    }

    // const getQuotesPromise = queryQuotes(queryStr);

    const getQuotesPromise = Promise.resolve(quotesFromFile);

    await getQuotesPromise.then( (retrievedQuotes) => {
      console.info("quotes from DB: ", retrievedQuotes.data, " returned");
      // throw new Error();
      const parsedQuotes = parseObj(retrievedQuotes.data);
      // console.log("returned quotes ", parsedQuotes);

      if (parsedQuotes && parsedQuotes.length>0){

        this.merged_retrievedQuotes_with_caches( parsedQuotes, lastUpdatedQuote );

        this.allCachedQs = this.pad_with_usr_ratings(this.allCachedQs, usrQuotes);
        // console.log("cw: uuser: got quotes from db: ", this.allCachedQs.length);
        this.localStorageCachesToUpdate.push('allCachedQs');

      }

    });   
    

    this.divy_allCachedQs_to_tags( tagsToDivy );
    // console.log("cw: this.cachedQsOfTags: ", this.cachedQsOfTags);

    consolePrintQuotes(this.cachedQs, "UPDATED QUOTES CACHE (from db) WITH");
    // console.log("this.cachedQs: ", this.cachedQs);
    // console.log("localStorage.getItem('cachedQs') ");
  }



  merged_retrievedQuotes_with_caches( retrievedQuotes, lastUpdatedQuote ){

    // Get ids of those retrieved
    let retrievedQuoteIndices = retrievedQuotes.map((rq)=>rq.quote_id);
  
    // Remove those quotes already in cache that have been updated
    this.allCachedQs = [...this.allCachedQs].filter((q)=>
      !retrievedQuoteIndices.includes(q.quote_id)
    );

    // Put all the updated (retrieved) quotes at the start of the cache
    this.allCachedQs = retrievedQuotes.concat(this.allCachedQs);

  }



  divy_allCachedQs_to_tags( tagsToDivy, forceDivy=true ){
    if (tagsToDivy===this.tagsInCache) return;
 
    // console.log("cw: tagsToDivy: ", tagsToDivy);
    // console.log("cw: ", this.allCachedQs, "  this.allCachedQs: ", typeof( this.allCachedQs));

    tagsToDivy.map( (tag) => {
      this.cachedQsOfTags[tag] = this.allCachedQs.filter((q)=> 
        q.tags && q.tags.includes(tag));
    });

    // this.localStorageCachesToUpdate.push('cachedQsOfTags');

    this.tagsInCache = Object.keys( this.cachedQsOfTags );
    // console.log("cw this.cachedQsOfTags: ", this.cachedQsOfTags);
  }



  pool_together_quotes_with_tags( tagsToPool ){
    this.qsByTagsPool[ this.tagsPoolStr ] = [];
    
    tagsToPool.map( (tag) => {
      // console.log("cw: this.cachedQsOfTags[ tag ]: ", Object.keys(this.cachedQsOfTags));
      //, " asking ", tag );
      this.qsByTagsPool[ this.tagsPoolStr ] = this.qsByTagsPool[ this.tagsPoolStr ].concat(
        this.cachedQsOfTags[ tag ]);
    });

    this.qsByTagsPool[ this.tagsPoolStr ] = [...new Set(this.qsByTagsPool[ this.tagsPoolStr ])];

    this.localStorageCachesToUpdate.push('qsByTagsPool');

    // console.log("this.qsByTagsPool[ this.tagsPoolStr ] : ", this.qsByTagsPool[ this.tagsPoolStr ] );
  }



  sort_quotes_for_feed( feedSelected ){

    let poolToSort = [...this.qsByTagsPool[ this.tagsPoolStr ]];
    if ( feedSelected === 'Fresh' ){
      this.feedsCache[ this.tagsPoolAndFeedStr ] = poolToSort.sort((a, b) => a.updated - b.updated);
    } else if ( feedSelected === 'Popular' ){
      // consolePrintQuotes(poolToSort, " cw: pool to sort", ['avg_rating']);
      poolToSort = poolToSort.filter((q)=>q.avg_rating!==null);
      this.feedsCache[ this.tagsPoolAndFeedStr ] = poolToSort.sort((a, b) => b.avg_rating - a.avg_rating);
      // consolePrintQuotes(this.feedsCache[ this.tagsPoolAndFeedStr ], " cw: popular sorted",  ['avg_rating']);
    } else if ( feedSelected === 'Tweeted' ){
      const thoseTweetChecked = poolToSort.filter((q)=> q.tw_most_recent_search_dt);
      // console.log("thoseTweetChecked: ", thoseTweetChecked);
      this.feedsCache[ this.tagsPoolAndFeedStr ] = thoseTweetChecked.sort((a, b) => a.tw_n_twts_found_total - b.tw_n_twts_found_total);

    } else if ( feedSelected === 'Yours' ){
      let YoursQuoteIds;

      if (this.usrQuotes && this.usrQuotes.length > 0) {
        const quotesWithValidIds = this.usrQuotes.filter((q) => Number.isInteger(q.quote_id));
        YoursQuoteIds = quotesWithValidIds.map((q) => q.quote_id);
      } else {
        YoursQuoteIds = [];
      }
      const archiveUsrQuotesWithValidIds = poolToSort.filter((q) => YoursQuoteIds.includes(q.quote_id));
      this.feedsCache[ this.tagsPoolAndFeedStr ] = this.merge_archived_quotes_with_usr_quotes( archiveUsrQuotesWithValidIds );

      this.localStorageCachesToUpdate.push('feedsCache');

    }

    // console.log("cw sorted for ", this.tagsPoolAndFeedStr ," : ", this.feedsCache[ this.tagsPoolAndFeedStr ]);
  }



  merge_archived_quotes_with_usr_quotes( archivedQuotes ) {
    console.log("uuser: merge_archived_quotes_with_usr_quotes, this.usrQuotes: ", this.usrQuotes);
    let mergedUsrQuotes = this.usrQuotes.map( (q, i) => {
      let mergedQuote;
      if (q.quote_id) {
        const matchedQuote = archivedQuotes.find((rq)=>
          (rq.quote_id===q.quote_id));
        mergedQuote = {
          ...q,
          ...matchedQuote,
        };
      } else {
        mergedQuote = q;
      }
      delete mergedQuote.quote_vers;
      mergedQuote.quote_id = mergedQuote.quote_id || mergedQuote.usr_quote_id;
      return mergedQuote;
    });
    mergedUsrQuotes = mergedUsrQuotes.filter((q)=>q.quote_vis);
    console.log('merged user quotes ', mergedUsrQuotes);

    return mergedUsrQuotes;
  }



  pad_with_usr_ratings( quotes, usrQuotes ){
    let usrRatedArchiveQuotes = usrQuotes.filter( (uq) => Number.isInteger(uq.quote_id) )
      .map((q)=>q.quote_id);

    console.log("uuser pad_with_usr_ratings, quotes: ", quotes.length, " usrQuotes: ", ...usrQuotes, " usrRatedArchiveQuotes: ", usrRatedArchiveQuotes);

    usrRatedArchiveQuotes.map( (uq) => {
      console.log("uuser quotes: ", quotes);
      const quoteIndex = quotes.findIndex((q) => q.quote_id === uq.quote_id);
      const validQuoteIndex = Number.isInteger(quoteIndex) && quoteIndex !== -1;
      if (validQuoteIndex){
        quotes[ quoteIndex ].userRating = uq.userRating;
      }
    });

    return [...quotes];
  }



  update_quote(values, quote_id, skipDb=true) {
    // const role = this.role;
    let queryParams = {
        'statement': 'update',
        values,
        quote_id
    }
    if (!skipDb){
      // this.updateQuotePromise = queryQuotes(queryParams);
    }
    console.log("values, quote_id: ", values, quote_id);
    return this.update_quote_in_cache( values, quote_id );
  }



  async update_quote_in_cache( objToMerge, quoteIdToMatch ){
    const quoteId = quoteIdToMatch || objToMerge.quote_id;
    if ( !quoteId ) {
      console.log("))cw: not updating cache because found quoteId of: ", quoteId);
      return;      
    }
    console.log("))cw: searching for quoteId in cache: ", quoteId);

    const removeQuote = (objToMerge.userRating===-1 && objToMerge.essence === '');
    // to let postgres handle quote id's where assigned uuid, deleting from obj  ??
    // delete objToMerge.quote_id;

    const keysInObjsToMerge = Object.keys(objToMerge);

    
    const cachedObjsToUpdate = ['feedsCache', 'qsByTagsPool', 'cachedQsOfTags'];
    // console.log("cw: QD.cache_set_rating cachedObjsToUpdate: ", cachedObjsToUpdate, " uuser: ");
    
    cachedObjsToUpdate.map( async (cachedObj, i) => {
      // console.log("cw: QD.cache_set_rating this: ", this);
      const feedsCacheKeys = Object.keys(this[cachedObj]);

      feedsCacheKeys.map(async (feedCache) => {
        const quoteIndexToUpdate = this[cachedObj][feedCache].findIndex((q) => q.quote_id === quoteId);
        if (Number.isInteger(quoteIndexToUpdate) && quoteIndexToUpdate !== -1) {
          if (!removeQuote){
            keysInObjsToMerge.map( (key) => {
              this[cachedObj][feedCache][quoteIndexToUpdate][key] = objToMerge[key];
            })
          } else {
            delete this[cachedObj][feedCache][quoteIndexToUpdate];
          }
         
          console.log("((())))cw: QD.cache_set_rating found quoteIndexToUpdate: ", quoteIndexToUpdate, " for cachedObj ", cachedObj, " of ", feedCache, " uuser: ", this[cachedObj][feedCache][quoteIndexToUpdate]);
        }
      });
    });

    const cachedArraysToUpdate = ['allCachedQs'];

    cachedArraysToUpdate.map( async (cachedArray) => {
      // console.log("cw: QD.cache_set_rating cachedArray: ", cachedArray, " this[cachedArray]: ", this[cachedArray]);
      const quoteIndexToUpdate = this[cachedArray].findIndex((q) => q.quote_id === quoteId);
      if (Number.isInteger(quoteIndexToUpdate) && quoteIndexToUpdate !== -1) {
        if (!removeQuote){
          keysInObjsToMerge.map( (key) => {
            this[cachedArray][quoteIndexToUpdate][key] = objToMerge[key];
          })
          console.log("((())))cw: QD.cache_set_rating found quoteIndexToUpdate: ", quoteIndexToUpdate, " for cachedArray ", cachedArray, this[cachedArray][quoteIndexToUpdate]);
        } else {
          delete this[cachedArray][quoteIndexToUpdate];
        }
      }
    });

    // console.log("cw: QD.cache_set_rating: ", );

    let propertiesToSave = cachedArraysToUpdate.concat(cachedObjsToUpdate);
    propertiesToSave = propertiesToSave.splice(propertiesToSave.indexOf('qsByTagsPool'), 1);
    return propertiesToSave;
  }

}

export default QuotesDat;

