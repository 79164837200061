export const make_range = (minVal=0, nVals=5, reverse) => {
  const rangeArray = [...Array(nVals).keys()].map(i => i + minVal);  
  if (reverse){
    return rangeArray.reverse();
  } else {
    return rangeArray;
  }
}

export const xsts = (aVar) => (aVar!==null && aVar!==undefined);

export const select_all_text_in_el = (el) => {
  let selection, range;
  if (window.getSelection && document.createRange) {
    selection = window.getSelection();
    range = document.createRange();
    range.selectNodeContents(el);
    selection.removeAllRanges();
    selection.addRange(range);
  } else if (document.selection && document.body.createTextRange) {
    range = document.body.createTextRange();
    range.moveToElementText(el);
    range.select();
  }
}

export const deselect_all_text_in_el = (el) => {
  if (window.getSelection) {window.getSelection().removeAllRanges();}
  else if (document.selection) {document.selection.empty();}
}

export const are_these_two_arrays_of_objs_eq = (arr1, arr2) => {
  if (arr1.length !== arr2.length){
    return false;
  }
  let areEqual = true;
  for (let i=0; i < arr1.length; i++){
    if ( areEqual && JSON.stringify( arr1[i] !== JSON.stringify(arr2[i] ))){
      areEqual = false;
      break;
    } else {
      break;
    }
  }
  return areEqual;
}

export const delay = ms => new Promise(r => setTimeout(r, ms));

export const isElementInViewport = (el) => {
  var rect = el.getBoundingClientRect();

  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}
