import './FeedItem.css';
import './ribbon.css';
import './chamferedBox.css';


import QuoteEntryOpts from './quoteEntryOpts/quoteEntryOpts';

import QuoteParagraph from './quoteParagraph/quoteParagraph';
import ToolBtn from './curatorTools/toolBtn/toolBtn';

import React from 'react';

import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import { DndTagCont } from './dndTagCont/dndTagCont.js';

import Ratings from './curatorTools/ratings/ratings';

import { select_all_text_in_el, deselect_all_text_in_el } from '../../../utils/utils.js';


class FeedItem extends React.Component {
    constructor(props) {
        super(props);
        this.txtSelected = false;
        this.onBlurQuoteParagraph = this.onBlurQuoteParagraph.bind(this);

        this.clickedCuratorTools = this.clickedCuratorTools.bind(this);
        this.click_set_essenceTxt = this.click_set_essenceTxt.bind(this);
        this.handle_textSelection = this.handle_textSelection.bind(this);
        this.toggle_set_essence_btn = this.toggle_set_essence_btn.bind(this);

        this.handle_quoteParagraph_pointerDown = this.handle_quoteParagraph_pointerDown.bind(this);
        this.handle_quoteParagraph_pointerUp = this.handle_quoteParagraph_pointerUp.bind(this);

        this.blankText = '                                                                                                                                                     ';

        this.clickedRating = this.clickedRating.bind(this);
        this.clickedEntrySaveToYours = this.clickedEntrySaveToYours.bind(this);

        this.onMouseDownFeedItem = this.onMouseDownFeedItem.bind(this);

        this.checkQuoteTextForChanges = this.checkQuoteTextForChanges.bind(this);
        this.updateTags = this.updateTags.bind(this);

        this.blurredAuthor = this.blurredAuthor.bind(this);
        this.keyDownHandler = this.keyDownHandler.bind(this);


        const { quote, userUuid, tagsSelected, isQuoteEntry } = props;

        const { essence, quote_vis, shelf, author, tags, avg_rating, n_ratings } = quote;

        const essenceIndexes = (essence) ? this.compute_essence_indexes(quote_vis, essence) : [quote_vis.length, quote_vis.length];
        const startSpanTxt = quote_vis.substring(0, essenceIndexes[0]);
        const endSpanTxt = quote_vis.substring(essenceIndexes[1]);

        const maxTags = 5;
        const tagsPadded = this.pad_tags(tags, maxTags, tagsSelected);

        const quoteUserRating = props.userRating;

        let userRating = (Number.isInteger(quoteUserRating)) ? Number(quoteUserRating) : 0;

        let avgRating = (avg_rating || null);
        let nRatings = (n_ratings || null);

        this.state = {
            quote,
            quote_vis,
            essence,
            shelf,
            startSpanTxt,
            endSpanTxt,
            author,
            tagsPadded,
            tagsSelected,
            tags,
            userRating,
            avgRating,
            nRatings,
            isQuoteEntry,
            userUuid
        }
    }


    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        // console.log('feed js componentWillReceiveProps finished mounting');

        if (nextProps.userRating !== this.state.userRating) {
            const userRating = nextProps.userRating;
            this.setState({
                userRating
            });
        }
    }




    clickedCuratorTools(ev) {
        const btnDat = ev.target.dataset;
        const shelf = Number(btnDat.curatorBtnVal);
        // console.log("clicked shelf ", shelf);
        const values = {
            shelf
        }

        this.update_quote(values, this.props.quote_)
        this.updateQuotePromise
            .then((msg) => {
                console.log("resolved update quote promise with msg: ", msg);
                this.setState({
                    shelf
                })
            });
    }



    keyDownHandler(ev) {
        console.log("ev: ", ev);
    }



    clickedEntrySaveToYours(ev) {
        let quoteSpansOuter = this.quoteParagraph.querySelector('.quoteSpansOuter');
        let enteredQuote = this.quoteParagraph.innerText.substring(1);
        enteredQuote = enteredQuote.substring(0, enteredQuote.length - 1).trim();
        let authorTxt = this.authorContainerEl.innerText.replaceAll('▀', '').trim();

        this.props.handleNewUsrQuoteFeedItems(enteredQuote, authorTxt);
        let startSpan = quoteSpansOuter.querySelector('.startSpan');
        console.log("startSpan: ", startSpan);
        startSpan.innerText = "";

    }



    click_set_essenceTxt(ev) {
        console.log("== click_set_essenceTxt ev.target: ", ev.target);
        this.handle_textSelection();

        const essence = this.essenceTxt;
        let quote_vis = this.state.quote_vis;

        const essenceIndexes = (essence) ? this.compute_essence_indexes(quote_vis, essence) : [quote_vis.length, quote_vis.length];
        const startSpanTxt = quote_vis.substring(0, essenceIndexes[0]);
        const endSpanTxt = quote_vis.substring(essenceIndexes[1]);
        console.log();
        console.log("== SETTING ESSENCE TEXT: ", essence);
        console.log();
        let values = {
            essence
        }

        this.props.updateQuoteHandlerFeedItems(values, this.props.quoteId, this.props.usrQuoteId);

        this.setState({
            essence,
            startSpanTxt,
            endSpanTxt
        });

        this.withinParagraphOrSetEssence = false;
        this.essenceSetable = false;
        this.toggle_set_essence_btn();
        // this.withinParagraphOrSetEssence.focus();
    }



    toggle_set_essence_btn() {
        console.log("toggling toggle_set_essence_btn: ");
        const essenceBtnPresent = (this.withinParagraphOrSetEssence && this.essenceSetable);
        const nlpMode = this.props.role.includes('nlp') || this.props.isUsrQuote;
        if (!nlpMode) return;

        if (!essenceBtnPresent && !this.essenceBtnOuter.classList.contains('hidden')) {
            this.essenceBtnOuter.classList.add('hidden');
        } else if (essenceBtnPresent) {
            this.essenceBtnOuter.classList.remove('hidden');
        }
    }



    handle_quoteParagraph_onDrag(ev) {
        // console.log('--------- checking quoteParagraph handle_quoteParagraph_onDrag: ', ev);
        ev.stopPropagation();
        ev.preventDefault();
        return false;
    }



    handle_quoteParagraph_pointerDown(ev) {
        const isQuoteSpan = (ev.target.classList.contains('quoteSpan'));
        const parentIsQuoteSpan = (ev.target.parentNode.classList.contains('quoteSpan'));
        const quoteSpanFamily = (isQuoteSpan || parentIsQuoteSpan);

        console.log("handle_quoteParagraph_pointerDown: ", ev.target, " innerText: ",
            ev.target.innerText.substring(0, 10), " parent is quotespan ", parentIsQuoteSpan);

        if (quoteSpanFamily) {

            this.withinParagraphOrSetEssence = true;
            this.handle_textSelection();
        } else {
            this.withinParagraphOrSetEssence = false;
        }
    }



    handle_quoteParagraph_pointerUp(ev) {
        console.log("pointerUp: ", ev.target, " innerText: ", ev.target.innerText.substring(0, 20));

        const eCl = ev.target.classList;

        const isQuoteSpan = (ev.target.classList.contains('quoteSpan'));
        const parentIsQuoteSpan = (ev.target.parentNode.classList.contains('quoteSpan'));
        const quoteSpanFamily = (isQuoteSpan || parentIsQuoteSpan);

        const legitEls = (quoteSpanFamily || eCl.contains('theQuote') || eCl.contains('invertedComma'));

        if (legitEls) {
            if (!this.props.isQuoteEntry) {
                this.withinParagraphOrSetEssence = true;
                this.handle_textSelection();
            }
            else if (eCl.contains('theQuote') && eCl.contains('isQuoteEntry')) {
                let startSpan = ev.target.querySelector('.startSpan');
                startSpan.focus();
                select_all_text_in_el(startSpan);
            }
        }
    }



    handle_quoteParagraph_mouseEnter(ev) {
        const isNlpRole = this.props.role.includes("nlp") || this.props.isUsrQuote;
        if (!isNlpRole) return;
        console.log("handle_quoteParagraph_mouseEnter ", ev.target)
        const isQuoteSpan = (ev.target.classList.contains('quoteSpan'));
        const parentIsQuoteSpan = (ev.target.parentNode.classList.contains('quoteSpan'));
        const quoteSpanFamily = (isQuoteSpan || parentIsQuoteSpan);

        if (quoteSpanFamily) {
            this.withinParagraphOrSetEssence = true;
            this.toggle_set_essence_btn();
        }
    }



    compute_essence_indexes(visText, essenceTxt) {
        let essenceStartI = visText.indexOf(essenceTxt);
        let essenceEndI = essenceStartI + essenceTxt.length;
        return [essenceStartI, essenceEndI];
    }



    handle_textSelection() {
        console.log("handle_textSelection!, withinParagraphOrSetEssence: ", this.withinParagraphOrSetEssence);
        if (!this.withinParagraphOrSetEssence) {
            this.essenceSetable = false;
            this.toggle_set_essence_btn();
            return
        }

        const v = document.getSelection();
        if (!v || !v.anchorNode || !v.anchorNode.data) return;
        // console.log("val: ", v);

        const anchorNodeTxt = v.anchorNode.data;
        const focusNodeTxt = v.focusNode.data;

        const sameQuoteSpan = (anchorNodeTxt === focusNodeTxt);

        let startI, endI;
        if (sameQuoteSpan) {
            console.log("anchorNodeTxt === focusNodeTxt");
            startI = Math.min(v.anchorOffset, v.focusOffset);
            endI = Math.max(v.anchorOffset, v.focusOffset);
            this.essenceTxt = anchorNodeTxt.substring(startI, endI);

        } else {
            if (anchorNodeTxt === this.state.startSpanTxt) {
                console.log("anchorNodeTxt === state.startSpanTxt");
                startI = v.anchorOffset;
                if (focusNodeTxt === this.state.essence) {
                    console.log("focusNodeTxt === state.essence");
                    endI = this.state.startSpanTxt.length + v.focusOffset;
                } else {
                    endI = this.state.startSpanTxt.length + this.state.essence.length + v.focusOffset;
                }
            } else if (focusNodeTxt === this.state.startSpanTxt) {
                console.log("focusNodeTxt === state.startSpanTxt");
                startI = v.focusOffset;
                if (anchorNodeTxt === this.state.essence) {
                    console.log("&& anchorNodeTxt === state.essence");
                    endI = this.state.startSpanTxt.length + v.anchorOffset;
                } else {
                    endI = this.state.startSpanTxt.length + this.state.essence.length + v.anchorOffset;
                }
            } else if (anchorNodeTxt === this.state.essence) {
                console.log("anchorNodeTxt === state.essence");
                if (focusNodeTxt === this.state.endSpanTxt) {
                    console.log("&& focusNodeTxt === state.endSpanTxt");
                    startI = this.state.startSpanTxt.length + v.anchorOffset;
                    endI = this.state.startSpanTxt.length + this.state.essence.length + v.focusOffset;
                }
                // else focus node is at the start (already handled)
            } else if (focusNodeTxt === this.state.essence) {
                console.log("focusNodeTxt === state.essence");
                if (anchorNodeTxt === this.state.endSpanTxt) {
                    console.log("&& anchorNodeTxt === state.endSpanTxt");
                    startI = this.state.startSpanTxt.length + v.focusOffset;
                    endI = this.state.startSpanTxt.length + this.state.essence.length + v.anchorOffset;
                }
            }
            this.essenceTxt = this.state.quote_vis.substring(startI, endI);
        }

        this.essenceSetable = (this.essenceTxt.length > 3);
        this.toggle_set_essence_btn();
    }



    blurredAuthor(ev) {
        const author = ev.target.innerText;
        console.log("on blur target: ", ev.target, " text: ", author);

        if (author !== this.state.author) {
            let values = {
                author
            }
            this.props.updateQuoteHandlerFeedItems(values, this.props.quoteId, this.props.usrQuoteId);
            this.setState({
                author
            });
        }
        deselect_all_text_in_el(ev.target);
    }



    clickedRating(ev) {
        let tempUserRating = Number(ev.target.dataset.temprating);
        tempUserRating = (tempUserRating === 0) ? -1 : tempUserRating;

        // console.log("clickedrating! new click ", tempUserRating, " old r: ", this.state.userRating);

        if (this.state.userRating === tempUserRating) return;
        console.log(ev.target);
        console.log("tempUserRating: ", tempUserRating);
        const userRating = (tempUserRating === 0) ? -1 : tempUserRating;

        if (userRating !== this.state.userRating) {
            console.log("setting user rating to: ", userRating);
            this.setState({
                userRating
            })
            const rateQuoteObj = {
                uuid: this.state.userUuid,
                quote_id: this.state.quote.quote_id,
                usr_quote_id: this.props.usrQuoteId,
                userRating
            }
            this.props.updateCacheRating(rateQuoteObj);
        }

        if (this.props.role.includes("nlp") && userRating === -1) {
            this.setState({
                dontRender: true
            })
        }
    }



    onMouseDownFeedItem(ev) {
        const eCl = ev.target.classList;
        const isFeedItem = eCl.contains('feedItem') || eCl.contains('quoteTagsContainer')
            || eCl.contains('ratingsContainer');
        console.log("mouseDownFeedItem: ", eCl, " isFeedItem? ", isFeedItem);

        if (isFeedItem) {
            this.withinParagraphOrSetEssence = false;
            this.toggle_set_essence_btn();
        }
    }



    onBlurQuoteParagraph(ev) {
        console.log("uuser: checking if quote text changed from ev ", this.state);

        if (!this.state.isQuoteEntry) {
            this.checkQuoteTextForChanges(ev);
            this.handle_textSelection();
        }
        deselect_all_text_in_el(ev.target);
    }



    clickQuoteEntry(ev) {
        console.log("ev: ", ev);
    }



    checkQuoteTextForChanges(ev) {
        console.log(ev.target, " uuser: checkQuoteTextForChanges is user quote: ", ev.target);
        this.withinParagraphOrSetEssence = false;
        // this.clickedQuoteSpans = [];

        const quote_vis = ev.target.innerText.trim();

        console.log("checkQuoteTextForChanges quote_vis: ", quote_vis);

        const changeInAllText = (quote_vis !== this.state.quote_vis);

        // console.log("checkQuoteTextForChanges do we have a change in text? ", changeInAllText);

        if (changeInAllText) { // changeInEssenceText){
            let { startSpanTxt, endSpanTxt, essence } = this.state;

            const changedTextWithinEssence = (!quote_vis.includes(essence));
            // const changedTextWithinStart = (!quote_vis.includes( startSpanTxt ));
            // const changedTextWithinEnd = (!quote_vis.includes( endSpanTxt ));


            console.log("checkQuoteTextForChanges do we have a changedTextWithinEssence? ", changedTextWithinEssence);
            if (changedTextWithinEssence) {
                essence = "";
                startSpanTxt = quote_vis;
                endSpanTxt = "";
            } else {
                const essenceIndexes = (essence) ? this.compute_essence_indexes(quote_vis, essence) : [quote_vis.length, quote_vis.length];
                startSpanTxt = quote_vis.substring(0, essenceIndexes[0]);
                endSpanTxt = quote_vis.substring(essenceIndexes[1]);
            }

            this.setState({
                quote_vis,
                essence,
                startSpanTxt,
                endSpanTxt
            });

            let values = {
                quote_vis,
                essence
            }

            this.props.updateQuoteHandlerFeedItems(values, this.props.quoteId, this.props.usrQuoteId);


        }
    }



    pad_tags(tags = [], maxTags = 6, tagsSelected) {
        if (tags === null || tags === undefined) return;
        tagsSelected = tagsSelected || this.state.tagsSelected;
        const paddedTags = tags.map((tag, i) => {
            if (tag) {
                const isSelected = (tagsSelected.indexOf(tag) > -1);
                return {
                    'text': tag,
                    'index': i,
                    'id': i,
                    'selected': isSelected
                }
            }
        });
        return paddedTags.slice(0, 6);
    }



    updateTags(tagsPadded) {
        console.log("updating tags in feed item: ", tagsPadded);

        const tags = tagsPadded.map((tag) => tag.text);

        let values = {
            tags
        }


        this.props.updateQuoteHandlerFeedItems(values, this.props.quoteId, this.props.usrQuoteId);

        tagsPadded = tagsPadded.slice(0, 6);
        this.setState({
            tags,
            tagsPadded
        });
    }



    onClickAuthor(ev) {
        select_all_text_in_el(ev.target);
    }



    handle_ribbon_dat(quote, isYoursQuote, isTweetedFeed) {
        let ribbonContainerClassName = "ribbonContainer";
        const produceRibbon = ((quote.tw_most_recent_search_dt && isTweetedFeed) || isYoursQuote);
        if (!produceRibbon) return { produceRibbon };

        const nTwtsFoundInTotal = Number(quote.tw_n_twts_found_total);
        const notRecentlyTweeted = (!nTwtsFoundInTotal || nTwtsFoundInTotal === 0);
        let ribbonTextTop = (notRecentlyTweeted) ? "Not Recently " : "Tweeted";
        ribbonContainerClassName += (notRecentlyTweeted) ? " notRecentlyTweeted" : " recentlyTweeted";

        // console.log("this.props.feedSelected: ", this.props.feedSelected, " isTweetedFeed: ", isTweetedFeed, " ribbonTextTop: ", ribbonTextTop);

        let ribbonTextBottom;
        if (!notRecentlyTweeted) {
            const tweetedToday = (Number(quote.tw_n_twts_last_day) > 0);
            if (tweetedToday) {
                ribbonTextBottom = "today";
            } else {
                const tweetedThisWeek = (Number(quote.tw_n_twts_last_week) > 0);
                if (tweetedThisWeek) {
                    ribbonTextBottom = "this week";
                } else {
                    const tweetedThisMonth = (Number(quote.tw_n_twts_last_month) > 0);
                    if (tweetedThisMonth) {
                        ribbonTextBottom = "this month";
                    } else {
                        ribbonTextBottom = "this year";
                    }
                }
            }
        } else {
            ribbonTextBottom = "Tweeted"
        }

        if (isYoursQuote) {
            ribbonTextTop = 'Yours';
            ribbonTextBottom = '';
            ribbonContainerClassName = "ribbonContainer yoursRibbon"
        }

        return { produceRibbon, ribbonTextTop, ribbonTextBottom, ribbonContainerClassName }
    }




    render() {
        const { quote, itemI, dontRender } = this.state;
        if (dontRender) return null;
        const { author, quote_id } = quote;
        const { isQuoteEntry, isUsrQuote } = this.props;
        const pristine_author = (author) ? this.state.author : "▀▀▀▀▀▀▀▀  ▀▀▀▀▀▀";

        const isNlpRole = this.props.role.includes("nlp") || isUsrQuote;
        const authorEditable = isNlpRole || isQuoteEntry;
        const paragraphEditable = isNlpRole || isQuoteEntry;
        const tagsEditable = isNlpRole;

        let theQuoteClassName = 'theQuote';
        theQuoteClassName += (isQuoteEntry) ? ' isQuoteEntry' : '';


        // const hasAcceptableUserRating = (!this.state.userRating || (this.state.userRating && this.state.userRating !== -1));
        // const passedModertion = (this.state.shelf !== 0 && this.state.shelf !== 1);
        // const shouldRender = (hasAcceptableUserRating && passedModertion);

        let feedItemClassName = 'feedItem';
        feedItemClassName += (isQuoteEntry) ? ' quoteEntry' : '';

        let feedAuthorContainerClassName = "authorInner";
        feedAuthorContainerClassName += (isQuoteEntry) ? ' isAnimated' : '';


        const isTweetedFeed = (this.props.feedSelected === 'Tweeted')

        const isYoursQuote = isUsrQuote || isQuoteEntry;

        const ribDat = this.handle_ribbon_dat(quote, isYoursQuote, isTweetedFeed);


        return (
            (
                <div className={feedItemClassName}
                    onMouseDown={this.onMouseDownFeedItem}>






                    <div className="feedItemInner">
                        <div className="authorContainer">
                            <div className="chamferedBox"></div>
                            <div className={feedAuthorContainerClassName}
                                ref={c => (this.authorContainerEl = c)}>
                                <span contentEditable={authorEditable}
                                    onClick={this.onClickAuthor}
                                    onInput={this.inputChanged}
                                    onKeyDown={this.keyDown}
                                    onBlur={this.blurredAuthor}
                                    spellCheck={false}
                                    onPaste={(e) => {
                                        e.preventDefault();
                                        const text = e.clipboardData.getData("text");
                                        document.execCommand("insertText", false, text);
                                    }}
                                    suppressContentEditableWarning={true}>{pristine_author}</span>
                            </div>
                        </div>



                        <div className="theQuoteAndRibbonContainer">

                            <div className={theQuoteClassName} ref={c => (this.quoteParagraph = c)}
                                onMouseUp={this.handle_quoteParagraph_pointerUp}
                                onMouseMove={this.handle_quoteParagraph_mouseMove}
                                onDragStart={this.handle_quoteParagraph_onDrag}
                                onClick={this.onClickQuoteEntry}>
                                <QuoteParagraph quoteId={quote.quote_id}
                                    startSpanTxt={this.state.startSpanTxt}
                                    essence={this.state.essence}
                                    endSpanTxt={this.state.endSpanTxt}
                                    isQuoteEntry={isQuoteEntry}
                                    searchText={this.props.searchText}
                                    blankText={this.blankText}
                                    quoteParagraphOnBlurHandler={this.onBlurQuoteParagraph}
                                    quoteParagraphOnPointerDownHandler={this.handle_quoteParagraph_pointerDown}
                                    quoteParagraphOnPointerUpHandler={this.handle_quoteParagraph_pointerUp}
                                    quoteParagraphOnKeyUpHandler={this.handle_quoteParagraph_pointerEv}
                                    quoteParagraphOnMouseEnterHandler={this.handle_quoteParagraph_mouseEnter}
                                    editable={paragraphEditable} />
                            </div>

                            {(ribDat.produceRibbon) && (
                            <div className={ribDat.ribbonContainerClassName}>
                                <div className="ribbon ribbon-top-right">
                                    <span>
                                        {ribDat.ribbonTextTop}<br />
                                        {ribDat.ribbonTextBottom}
                                    </span>
                                </div>
                            </div>
                        )}
                       
                        </div>

                    


                        {(!isQuoteEntry && (
                            <div className="ratingsAndTagsOuter">
                                <div className="ratingsContainer">
                                    <Ratings key={itemI}
                                        minVal={-1}
                                        nVals={6}
                                        currShelf={this.state.shelf}
                                        userRating={this.state.userRating}
                                        avgRating={this.state.avgRating}
                                        nRatings={this.state.nRatings}
                                        quoteVis={this.state.quote.quote_vis}
                                        quoteId={quote_id}
                                        handleClickRatings={this.clickedRating}
                                        role={this.state.role} />
                                </div>
                                <div className="quoteTagsContainer">
                                    {(this.state.tagsPadded && (
                                        <DndProvider backend={HTML5Backend}>
                                            {(
                                                <DndTagCont tagsPadded={this.state.tagsPadded}
                                                    editable={tagsEditable}
                                                    updateTags={this.updateTags} />
                                            )}
                                        </DndProvider>
                                    )
                                    )}
                                </div>
                            </div>
                        ))}
                         {(isQuoteEntry && (
                            <QuoteEntryOpts handleClickedEntrySaveToYours={this.clickedEntrySaveToYours} />
                        ))}

        

                    </div>
                    {((isNlpRole) && (
                        <div ref={c => (this.essenceBtnOuter = c)} className={`essenceBtnOuter ${(this.essenceSetable) ? '' : 'hidden'}`}>
                            <ToolBtn btnText='Set Essence' quoteId={quote_id} clickHandlerCurateBtn={this.click_set_essenceTxt} />
                        </div>
                    )
                    )}


                </div>
            )
        )
    }
}

export default FeedItem;