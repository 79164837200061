
import React from 'react';

import { xsts } from '../../../../../utils/utils.js';

import './ratings.css';


class Ratings extends React.Component {
  constructor(props) {
    super(props);
    let avgRating = (props.avgRating || 0);
    let userRating = (props.userRating || 0);
    let nRatings = (props.nRatings || 0);

    this.state = {
      userRating,
      avgRating,
      nRatings
    }

    this.handleMouseMoveRatingsOuter = this.handleMouseMoveRatingsOuter.bind(this);
    this.handleMouseLeaveRatingsOuter = this.handleMouseLeaveRatingsOuter.bind(this);

  }

  componentDidMount() {
  }

  handleMouseEnterRatingsOuter(ev){
    // console.log("handleMouseEnterRatingsOuter ", ev.target);
  }
  
  handleMouseLeaveRatingsOuter(ev){
    console.log("handleMouseLeaveRatingsOuter ", ev.target, " this.state.userRatin: ", this.state.userRating);
    // console.log("fill ev ", ev);
    const starFill = (this.state.userRating) ? Math.max(this.state.userRating, 0) : 0;
    ev.target.style.setProperty('--user-rating', starFill);
    const eCl = ev.target.classList;
    if ( this.state.userRating < 0 ){
      if ( !eCl.contains('thumbFilled') ){
        eCl.add('thumbFilled');
      }
    } else {
      eCl.remove('thumbFilled');
    }

  }

  setTempUserRating(tempUserRating){
    this.tempUserRating = tempUserRating;
  }

  handleMouseMoveRatingsOuter(ev){
    // console.log("handleMouseMoveRatingsOuter: ", ev);
    if (ev.target){
      // console.log("handling mouse move over: ", ev.target);
      let rect = ev.target.getBoundingClientRect();
      let x = ev.clientX - rect.left; //x position within the element.
      let offsetWidth = Number(ev.target.clientWidth);
  
      this.tempUserRating = Math.ceil(x / offsetWidth * 6) - 1;

      const eCl = ev.target.classList;
      if (this.tempUserRating<=0){
        if (!eCl.contains('thumbFilled')){
          eCl.add('thumbFilled');
        }
      } else {
        eCl.remove('thumbFilled');
      }
      // console.log("this.section: ", section);
      ev.target.style.setProperty('--user-rating', this.tempUserRating);
      ev.target.dataset.temprating = this.tempUserRating;
      // console.log("this.tempUserRating: ", this.tempUserRating, " rect: ", rect, " offsetWidth: ", offsetWidth, " mouseX: ", x);
    }
    
    // var y = ev.clientY - rect.top;  //y position within the element.
  }

  componentWillReceiveProps(nextProps) {
    // console.log("componentWillReceiveProps ratings.js nextProps.userRating: ",nextProps.userRating, " this.state.userRating: ", this.state.userRating);
    if (nextProps.userRating !== this.state.userRating){
      const userRating = nextProps.userRating;
      const avgRating = nextProps.avgRating;

      this.setState({
        userRating,
        avgRating
      });
    }
  }



  render() {

    const {avgRating, nRatings } = this.state;

    let userRating = this.state.userRating || 0;

    let userRatingStyle = {
      "--user-rating": userRating
    };

    if ( userRating && avgRating){
      userRatingStyle['--avg-rating'] = avgRating
    }

    let ratingsOuterClassName = 'ratingsOuter';
    ratingsOuterClassName += (userRating < 0) ? " thumbFilled " : "";

    let avgRatingsOuterClassName = 'ratingsOuter avgRatings';
    avgRatingsOuterClassName += (avgRating < 0) ? " thumbFilled " : "";
    // console.log("ratingsOuterClassName: ", ratingsOuterClassName);

    const showAvgRating = ( xsts(userRating) && userRating!==0 
      && this.state.avgRating!==null )

    const showNRatings = (showAvgRating && ( nRatings!==null ) && ( nRatings > 0));
    // let ratingsText = nRatings + " vote";
    // ratingsText+= (this.state.nRatings > 1) ? 's' : '';
    let ratingsText = String(nRatings);


    // console.log("showAvg: ", showAvgRating, "showNRatings: ", showNRatings, " userRating: ", this.state.userRating," avgRating: ", this.state.avgRating, " nRatings: ", this.state.nRatings, " quote: ", this.props.quoteVis.substring(0,15));

    return (
      <div className="ratingsCont">
      {
        (showAvgRating && 
          (
            <>
            <div className={avgRatingsOuterClassName} style={userRatingStyle}></div>
            {
              (showNRatings && 
                <div className="nRatingsDiv" style={userRatingStyle}>{ratingsText}</div>
              
              )}
            </>
          )
        )
      }
      <div className={ratingsOuterClassName} 
        style={userRatingStyle}
        onMouseEnter={this.handleMouseEnterRatingsOuter}
        onMouseLeave={this.handleMouseLeaveRatingsOuter}
        onMouseMove={(ev) => this.handleMouseMoveRatingsOuter(ev)}
        
        onClick={this.props.handleClickRatings}>
      </div>
   
      
      </div>
    )
  }
}

export default Ratings;
