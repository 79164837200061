import './quoteEntryOpts.css'
import ToolBtn from '../curatorTools/toolBtn/toolBtn';

import React from 'react';

import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
const functions = getFunctions();
const newLocal = 'localhost';
if (process.env.NODE_ENV === 'development') {
    connectFunctionsEmulator(functions, newLocal, 5001);
}
const queryQuotes = httpsCallable(functions, 'queryQuotes');


class QuoteEntryOpts extends React.Component {
    constructor(props) {
        super(props);
    }


    componentDidMount() {
    }

    componentWillReceiveProps(nextProps) {
        // if (nextProps.userRating !== this.state.userRating){
        //   const userRating = nextProps.userRating;
        //   this.setState({
        //     userRating
        //   });
        // }
    }
    
     
    render() {
        return (
          (
          <div className="quoteEntryOpts" onClick={this.props.handleClickedEntrySaveToYours}>
              <ToolBtn btnText="Save to Yours"
                btnType="post" />
          </div>
          )
        )
    }
}

export default QuoteEntryOpts;