/* eslint-disable no-lone-blocks */
import { memo, useCallback, useState } from 'react'
import { useDrop } from 'react-dnd'
import { DndTag } from './dndTag/dndTag.js'
import '../../../tagsList/tagsList.css'
import update from 'immutability-helper'


export const DndTagCont = memo(function DndTagCont({updateTags, tagsPadded, editable}){
  // const editable = tagsPadded.editable;
  let [dndtags, setDndTags] = useState(tagsPadded)
  const findDndTagById = useCallback(
    (id) => {
      const dndtag = dndtags.filter((t) => t.id === id)[0]
      return {
        dndtag,
        index: dndtags.indexOf(dndtag),
      }
    },
    [dndtags],
  )

  const findDndTagsByText = useCallback(
    (text) => {
      
      const foundDndTags = dndtags.filter((t) => t.text === text)[0]
      console.log("findDndTagsByText: '", text ,"' found? ", foundDndTags )
      return foundDndTags;
      
    },
    [dndtags],
  )

  const moveDndTag = useCallback(
    (id, atIndex) => {
      const { dndtag, index } = findDndTagById(id);

      setDndTags(
        update(dndtags, {
          $splice: [
            [index, 1],
            [atIndex, 0, dndtag],
          ],
        }),
      )
      // console.log("AFTER MOVE DNDTAGS: ", structuredClone(dndtags).map((t)=>t.text));

    },
    [findDndTagById, dndtags, setDndTags],
  )

  const addTag = useCallback(
    (tagText) => {
      console.log("addTag dndTags prior ", dndtags);
      const newTag = {
        'id' : dndtags.length,
        'index' : dndtags.length,
        'text' : tagText
      }
      const newDndTags = update(dndtags, {
        $push: [newTag],
      })
      setDndTags(
        newDndTags,
      )
      updateTags(
        newDndTags
      )
      console.log("attempting to add dndtag: ", newTag);
      console.log("addTag dndTags post ", dndtags)

      // console.log("AFTER MOVE DNDTAGS: ", structuredClone(dndtags).map((t)=>t.text));

    },
    [dndtags, setDndTags, updateTags],
  )
  const delTag = useCallback(
    (id, atIndex) => {
      console.log("delTag dndTags prior ", dndtags)
      console.timeLog("deleting id: ", id, " atIndex: ", atIndex);
      const newDndTags = update(dndtags, {
        $splice: [[atIndex, 1]]
      })
      setDndTags(
        newDndTags,
      )
      updateTags(
        newDndTags
      )
      // console.log("AFTER MOVE DNDTAGS: ", structuredClone(dndtags).map((t)=>t.text));

    },
    [dndtags, setDndTags],
  )
  const getDndTags = useCallback(
    () => {
   
    },
    [dndtags],
  )
  const [, drop] = useDrop(() => ({ accept: 'dndtag' }))
  return (
    <div ref={drop} className='tagsListContainer rightAlign'>
      {dndtags.map((dndtag, i) => (
        <DndTag
          key={dndtag.id}
          id={dndtag.id}
          text={dndtag.text}
          moveDndTag={moveDndTag}
          findDndTagById={findDndTagById}
          findDndTagsByText={findDndTagsByText}
          addTag={addTag}
          delTag={delTag}
          getDndTags={getDndTags}
          editable={editable}
          isLast={false}
          draggable={true}
          isTagSelected={dndtag.selected}

        />
      ))}
      { editable && (
        <DndTag
          key={dndtags.length}
          id={dndtags.length}
          text={'+'}
          findDndTagById={findDndTagById}
          findDndTagsByText={findDndTagsByText}
          addTag={addTag}
          delTag={delTag}
          getDndTags={getDndTags}
          editable={editable}
          isLast={true}
          draggable={false}

      />
      )
      }
    </div>
  )
})
