
import React from 'react';
import './quoteSpans.css'

import SearchSpan from './searchSpan/searchSpan';

import { select_all_text_in_el } from '../../../../../utils/utils.js';


class QuoteSpans extends React.Component {
  constructor(props) {
    super(props);
    // this.state = {
    //   startSpanTxt: props.startSpanTxt,
    //   essence: props.essence,
    //   endSpanTxt: props.endSpanTxt,
    // };

    this.onClickQuoteEntry = this.onClickQuoteEntry.bind(this);
    this.blankText = "";
  }


  componentDidMount() {
  }

  compute_essence_indexes(visText, essenceTxt) {
    let essenceStartI = visText.indexOf(essenceTxt);
    let essenceEndI = essenceStartI + essenceTxt.length;
    return [essenceStartI, essenceEndI];
  }


  selectStartTest(ev) {
    console.log("selectStartTest ev: ", ev.target.selectionStart);
  }

  cancel_ev(ev) {
    // ev.stopPropagation();
    ev.preventDefault();
    // return false;
  }

  onPointerDown(ev) {
    console.log("onPointerDown: ", ev);

    ev.preventDefault();
    // ev.stopPropagation();
    return false;
  }

  onClickQuoteEntry(ev) {

    const eCl = ev.target.classList;
    console.log("In quote spans: ", ev.target);
    if (!eCl.contains('ensureMaxWidth') || !this.props.isQuoteEntry) return;
    // if (){

    // }
    // const nConstants = ev.target.innerText.trim().length;

    // ev.target.innerText = ev.target.innerText.trim();

    let startSpan = this.mainSpan.querySelector('.startSpan');
    // select_all_text_in_el(startSpan);

    startSpan.focus();
  }

  keyDownHandler(e) {
    var key=e.keyCode || e.which;
    if (key==13){
      e.preventDefault();
      e.stopPropagation();
      return false;
    }

  }

  

  render() {

    let { quoteId, role, editable, isQuoteEntry, searchText, startSpanTxt, essence, endSpanTxt } = this.props;
    // console.log("quotespan props ", this.props);
    // console.log("contentEditableVal: ", contentEditableVal);

    return (
      <span ref={c => (this.mainSpan = c)} id={`quoteSpans${quoteId}`} className="quoteSpansOuter"
        contentEditable={(editable)} suppressContentEditableWarning={true}
        onBlur={this.props.quoteSpansOnBlurHandler}
        onPointerDown={this.props.quoteSpansOnPointerDownHandler}
        onKeyPress={this.keyDownHandler}
        onMouseUp={this.props.quoteSpansOnPointerUpHandler}
         onKeyUp={this.props.quoteSpansOnPointerUpHandler}
        onMouseEnter={this.props.quoteSpansOnMouseEnterHandler}
        onDragStart={this.cancel_ev}
        spellCheck={false}
        onPaste={(e) => {
          e.preventDefault();
          const text = e.clipboardData.getData("text");
          document.execCommand("insertText", false, text);
        }}>

        {(!isQuoteEntry && (
          <>
            <SearchSpan spanType='startSpan' spanText={startSpanTxt} searchText={searchText}></SearchSpan>
            <SearchSpan spanType='essenceSpan' spanText={essence} searchText={searchText}></SearchSpan>
            <SearchSpan spanType='endSpan' spanText={endSpanTxt} searchText={searchText}></SearchSpan>
          </>
        ))}
         {(isQuoteEntry && (
           <>
            <SearchSpan spanType='startSpan' spanText={' '} searchText={''}></SearchSpan>
           </>


        ))}

      </span>
    );
  }
}

export default QuoteSpans;
