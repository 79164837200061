// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA91jT2FpIN622CHXsnWM4ilA76weHTGDk",
  authDomain: "tqh-nbt.firebaseapp.com",
  projectId: "tqh-nbt",
  storageBucket: "tqh-nbt.appspot.com",
  messagingSenderId: "550495044316",
  appId: "1:550495044316:web:0559c8bc62fc4c517a6a19"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);