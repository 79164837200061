
import { get_sess_obj } from  '../utils/sess.js';

import { are_these_two_arrays_of_objs_eq, delay} from  '../utils/utils.js';

import { saveCache, parseObj, loadAndParse, clearCache } from "../utils/cacheUtils.js";

import { v4 as uuidv4 } from 'uuid';


// import { getFunctions, httpsCallable, connectFunctionsEmulator } from "firebase/functions";
// const functions = getFunctions();
const newLocal = 'localhost';
// if (process.env.NODE_ENV === 'development') {
//   connectFunctionsEmulator(functions, newLocal, 5001);
// }

// const initSess = httpsCallable(functions, 'initSess');
// // const queryQuotes = httpsCallable(functions, 'queryQuotes');
// const rateQuote = httpsCallable(functions, 'rateQuote');
// const handleUsrQuote = httpsCallable(functions, 'handleUsrQuote');


class UserDat {
  constructor( role ){

    // This needs to be the first thing to load, as the populated quotes are partly dependent
    // on userQuotes to populate ratings.
    // clearCache('usrQuotes');

    this.role = role;
    this.usrQuotes = loadAndParse('usrQuotes') || [];

    console.log("uuser: loaded usrQuotes from localStorage: ", this.usrQuotes);
    // this.set_user_dat_from_db = this.set_user_dat_from_db.bind(this);

    this.sessObj = get_sess_obj();
    this.uuid = localStorage.getItem('myuuid') || uuidv4();
    localStorage.setItem('myuuid',this.uuid);
    // this.set_user_dat_from_db(role);

    // this.insert_usr_quote = this.insert_usr_quote.bind(this);
  }



  set_user_dat_from_db(roler){
    // const initSessResultPromise = initSess({
    //   uuid: this.uuid,
    //   sessObj: this.sessObj,
    //   roler,
    // });

    // initSessResultPromise.then( (userObj) => {
    //   console.log("uuser: userObj ", userObj);
    //   const userFromPromise = userObj.data.user;
    //   console.log("uuser: this.usrQuotes: ", this.usrQuotes, " userFromPromise " , userFromPromise);
    //   const shouldUpdateUsrQuotes = !this.usrQuotes || this.usrQuotes.length===0 ||
    //     (userFromPromise.usr_quotes && 
    //       !are_these_two_arrays_of_objs_eq( this.usrQuotes, userFromPromise.usr_quotes ));
    //   console.log("uuser this.usrQuotes: ", this.usrQuotes)
    //   if (shouldUpdateUsrQuotes){
    //     this.usrQuotes = userFromPromise.usr_quotes || [];
    //   }
    //   if (this.usrQuotes){
    //     saveCache( 'usrQuotes', this.usrQuotes );
    //   }

    // })
    // .catch((err)=>{
    //   console.log("uuser: err, probably offline. ", err, " and this.usrQuotes set to: ", this.usrQuotes);
    // });
  }



  set_rating( ratedQuoteObj ){
    // rateQuote( ratedQuoteObj);
    this.cache_set_rating( ratedQuoteObj );
  }



  cache_set_rating( ratedQuoteObj ){
    this.usrQuotes = this.usrQuotes || [];

    let quoteIndexToUpdate;
  

    if ( ratedQuoteObj.quote_id ) {
      quoteIndexToUpdate = this.usrQuotes.findIndex((q) => q.quote_id === ratedQuoteObj.quote_id);
    } else {
      quoteIndexToUpdate = this.usrQuotes.findIndex((q) => q.usr_quote_id === ratedQuoteObj.usr_quote_id);
    }

    if (Number.isInteger(quoteIndexToUpdate) && quoteIndexToUpdate !== -1) {
      this.usrQuotes[ quoteIndexToUpdate ].userRating = ratedQuoteObj.userRating;
    } else {
      this.usrQuotes = [ratedQuoteObj].concat(this.usrQuotes);
    }

    // console.log("uuser: saving |||| usrQuotes with a rating added! ", this.usrQuotes);
    console.log("UD.cache_set_rating this.usrQuotes: ", this.usrQuotes, " ratedQuoteObj: ", ratedQuoteObj);

    saveCache('usrQuotes', this.usrQuotes);
  }



  async insert_new_usr_quote( quote_vis, author ){
    console.log("uuser: inserting new usr quote: ", quote_vis, author);
 
    const quote_id = null;
    const created = new Date();
    const is_usr_q = true;
    const usr_quote_id = uuidv4();

    const quote = {
      usr_quote_id,
      created,
      quote_id,
      quote_vis,
      author,
      is_usr_q,
    };

    this.usrQuotes = [quote].concat(this.usrQuotes);
    saveCache('usrQuotes', this.usrQuotes);

    // db verison disabled:

    // const quote_vers = {...quote};
    // try {

    //   await this.insert_usr_quote(text, author).then((newUsrQuoteObj)=>{
    //     console.log("uuser: newUsrQuoteObj: ", newUsrQuoteObj.data);
    //     this.usrQuotes = [newUsrQuoteObj.data].concat(this.usrQuotes);
    //     saveCache('usrQuotes', this.usrQuotes);
    //   });
    // }
    // catch (err){
    //   console.log('epic failure in dbquotes.js, getQuotes ', err);
    // }
  }



  // async insert_usr_quote(quote_vis, author) {
  //   let values = {
  //       quote_vis,
  //       author,
  //       uuid: this.uuid
  //   }

  //   let dataObj = {
  //       'statement': 'insert',
  //       values
  //   };

  //   return handleUsrQuote( dataObj );
  // }

}


export default UserDat;