
import React from 'react';
import './tagsList.css'
import Tag from '../tag/tag'


class TagsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tags: props.tags,
      tagsSelected: props.tagsSelected
    }

  }


  componentDidMount() {

  }

  tagInTagsSelected(tag, selectedTags){
    return (selectedTags.indexOf(tag) > -1);
  }

  render() {

    // console.log("tagslist tags: ", this.props.tags);
    const tags = (this.props.tags) ? this.props.tags : this.state.tags;
    const tagsSelected = (this.props.tagsSelected) ? this.props.tagsSelected : [];

    return (
        <div className={`tagsListContainer ${(!this.props.isSidebar) ? "rightAlign" : "isSidebar"}`}>
          {
            tags.map((tag) => (
              <Tag text={tag} key={tag} clickHandlerTag={this.props.clickHandlerApp} selected={this.tagInTagsSelected(tag, tagsSelected)} />
            ))
          }
        </div>
    );
  }
}

export default TagsList;
