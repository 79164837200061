
import React from 'react';
import './searchbar.css'


class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: this.props.searchText
    }


    // this.onKeyDownSearchInput = this.onKeyDownSearchInput.bind(this);
    
  }


  
  componentDidMount() {
    console.log("searchbar comp did mount with el: ", this.refSearchInput);
    const searchText = this.props.searchText;
    // this.refSearchInput.value = (searchText) ? searchText : '';
    this.refSearchInput.focus();
  }

  componentDidUpdate() {
    // console.log("searchbar comp did update with el: ", this.refSearchInput);
    // const searchText = this.props.searchText;
    // this.refSearchInput.value = (searchText) ? searchText : '';
    this.refSearchInput.focus();
  }

  onChangeSearchInput(ev) {
    console.log("onChangeSearchInput: ", ev.target.value);
    // this.props.handleSearchKeyPressFeedItems();
  }



  render() {
    console.log("SEARCH received props: ", this.props);

    return (
      <div className="searchBarContainerDiv">
      <form action="" className="searchBar">
        <input id="searchInput" autoFocus type="search" name="search" autoComplete="off"
          onChange={this.props.handleSearchKeyPressFeedItems}
          ref={c => (this.refSearchInput = c)}
          />
          <button className="searchBtn" type="submit"></button>
      </form>

      </div>

    )}
}

export default SearchBar;
