
import React from 'react';
import './tag.css'


class Tag extends React.Component {
  constructor(props) {
    super(props);
    
  }


  componentDidMount() {

  }





  render() {

    return (
      <div className={`tagContainer ${(this.props.selected) ? "selected" : ""}`} onClick={this.props.clickHandlerTag} data-tag-text={this.props.text}>
        <span className="tagText" data-tag-text={this.props.text}>{this.props.text} </span>
      </div>
    );
  }
}

export default Tag;
