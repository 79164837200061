export const sanitiseDates = (arrayOfProps) => {
  
  return arrayOfProps.map( (q) => {
    if (q.created){
      q.created = new Date(q.created);
    }
    if (q.created){
      q.updated = new Date(q.updated);
    }
    return q});

}

export const sanitiseDatesOfObjs = (parsedObj) => {
  // let objsParse = JSON.parse(objs);
  // console.log("type of data obj mapped: ", typeof(objs));
  const isArray = Array.isArray(parsedObj) ;
  if (!isArray){
    let keys = Object.keys( parsedObj );
    console.log("keys: ", keys);
    keys.map((key)=>{
      parsedObj[key] = sanitiseDates(parsedObj[key])
    });
  } else {
    parsedObj = sanitiseDates(parsedObj);
  }
  // console.log("objs parsed ", objsParse);

  return parsedObj;
}

export const loadAndParse = (cacheName) => {
  const loadedObj = localStorage.getItem( cacheName ) || null;
  const objType = typeof( obj );
  // console.log("should we parse ", cacheName, " of type: ", objType);
  return parseObj(loadedObj)
}

export const parseObj = (obj) => {
  // console.log(" attempting to parse obj: ", obj);
  try{
    const parsedObj = JSON.parse(obj);
    const objType = typeof( obj );
    if (parsedObj && objType && objType!=='string'){
      return sanitiseDatesOfObjs( parsedObj );}
    else {
      return parsedObj;
    }
  } catch (err) {
    console.log("not sanitising dates because obj is...");
    return obj;
  }
 



}

export const clearCache = (cacheName) => {
  localStorage.removeItem(cacheName);
}

export const clearAllCache = () => {
  localStorage.clear();
}

export const getObjSize = (obj) => {
  return new Blob( [JSON.stringify(obj)]).size 
}

export const printObjsInLocalStorage = () => {
  let keys = Object.keys(localStorage);
  console.log(); 

  keys.map( (key) => {
    const loadedObj = loadAndParse(key);
    const bytes = getObjSize(loadedObj);
    // console.log("key: ", key, " bytes: ", bytes);
  });
  // console.log("all objects in localStorage: ", keys);
  // console.log();
}

// export const  = () => {

// }

export const saveCache = (cacheName, cacheDat) => {
  printObjsInLocalStorage();
  let cacheCompatibleDat;

  let keys;
  try {
    cacheCompatibleDat = JSON.stringify(cacheDat) ;
    keys = Object.keys(cacheDat)
  } catch (err) {
    cacheCompatibleDat = cacheDat;
    console.log("err, must not be an obj or array. ", typeof(cacheDat));
  }

  // console.info("saving ", cacheName, " with keys: ", keys, " with dat: ", cacheDat, " of size: ", getObjSize(cacheDat), " cacheCompatibleDat with: ", cacheCompatibleDat);
  localStorage.setItem( cacheName, cacheCompatibleDat );
}