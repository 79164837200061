/* eslint-disable no-useless-constructor */

import React from 'react';
import './quoteParagraph.css'
import QuoteSpans from './quoteSpans/quoteSpans';

class QuoteParagraph extends React.Component {
  constructor(props) {
    super(props);
  }


  componentDidMount() {

  }





  render() {

    let {  startSpanTxt, quoteId, editable, endSpanTxt, essence, searchText } = this.props;
    return (
      <span className="quoteParagraph">
        <span className="invertedComma startInvertedComma">&ldquo;</span>
        <QuoteSpans quoteId={quoteId} editable={editable} startSpanTxt={startSpanTxt} 
          essence={essence} endSpanTxt={endSpanTxt} 
          searchText={searchText}
          blankText={this.props.blankText}
          isQuoteEntry={this.props.isQuoteEntry}
          quoteSpansOnBlurHandler={this.props.quoteParagraphOnBlurHandler}
          quoteSpansOnSelectionHandler={this.props.quoteParagraphOnSelectionHandler}
          quoteSpansOnPointerDownHandler={this.props.quoteParagraphOnPointerDownHandler}
          quoteSpansOnPointerUpHandler={this.props.quoteParagraphOnPointerUpHandler}
          quoteSpansOnMouseEnterHandler={this.props.quoteParagraphMouseEnterHandler}
        />
        <span className="invertedComma endInvertedComma">&rdquo;</span>

      </span>
    );
  }
}

export default QuoteParagraph;
